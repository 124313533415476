import { Link } from '@remix-run/react';
import { Navigate, Route, Routes } from '@remix-run/react';
import { useMemo } from 'react';

import {
  type DtoChannel,
  type DtoChannelProgramLink,
} from '@lp-lib/api-service-client/public';

import { Loading } from '../../Loading';
import {
  ProgramDetailLayout,
  type ProgramDetailTabItem,
  ProgramDetailTabs,
} from '../../Program';
import { useBasicProgramFromLink } from '../../Program/usePrograms';
import { useCelebration } from '../hooks/useCelebration';
import { type CelebrationTabProps } from '../types';
import { useCelebrationPermission } from '../utils';
import { CelebrationTabAdmin } from './CelebrationTabAdmin';
import { CelebrationTabMessages } from './CelebrationTabMessages';
import { CelebrationTabParticipants } from './CelebrationTabParticipants';
import { CelebrationUpcomingTab } from './CelebrationTabUpcoming';

function PageError() {
  return (
    <div className='w-full flex-1 flex flex-col justify-center items-center gap-3 text-white'>
      <div className='text-2xl font-bold'>Something went wrong</div>
      <div className='text-lg mb-5'>
        We had trouble loading this celebration. Please try again.
      </div>
      <Link to='/home'>
        <button type='button' className='btn btn-primary w-40 h-10'>
          Back Home
        </button>
      </Link>
    </div>
  );
}

function PageLoading() {
  return (
    <div className='w-full flex-1 flex justify-center items-center'>
      <Loading text='' />
    </div>
  );
}

function CelebrationTabBar(props: CelebrationTabProps) {
  const { channel, permission } = props;

  const items = useMemo(() => {
    const arr: ProgramDetailTabItem[] = [];
    arr.push({
      title: `Participants(${channel.membersCount})`,
      url: './participants',
    });
    if (permission.editable) {
      arr.push({
        title: 'Messages',
        url: './messages',
      });
    }
    arr.push({
      title: 'Upcoming',
      url: './upcoming',
    });
    if (permission.editable) {
      arr.push({
        title: 'Admin',
        url: './admin',
      });
    }
    return arr;
  }, [channel.membersCount, permission.editable]);

  return <ProgramDetailTabs channelId={channel.id} items={items} />;
}

function CelebrationTab(props: CelebrationTabProps) {
  return (
    <Routes>
      <Route
        index
        element={<Navigate to={'participants'} replace></Navigate>}
      />
      <Route
        path='details'
        element={<Navigate to={'../participants'} replace></Navigate>}
      />
      <Route
        path='participants'
        element={<CelebrationTabParticipants {...props} />}
      />
      <Route path='messages' element={<CelebrationTabMessages {...props} />} />
      <Route path='upcoming' element={<CelebrationUpcomingTab {...props} />} />
      <Route path='admin' element={<CelebrationTabAdmin {...props} />} />
    </Routes>
  );
}

export function CelebrationProgram(props: {
  channel: DtoChannel;
  programLink: DtoChannelProgramLink;
}): JSX.Element {
  const { channel, programLink } = props;

  const permission = useCelebrationPermission({ channel });
  const { celebration, isLoading, error, mutate } = useCelebration({
    id: programLink.programTargetId,
  });
  const {
    data: program,
    isLoading: isProgramLoading,
    error: loadProgramError,
  } = useBasicProgramFromLink(programLink);
  if (isLoading || isProgramLoading) return <PageLoading />;
  if (error || !celebration) return <PageError />;
  if (loadProgramError || !program) return <PageError />;

  return (
    <ProgramDetailLayout {...props} program={program}>
      <CelebrationTabBar
        channel={channel}
        programLink={programLink}
        celebration={celebration}
        permission={permission}
        mutateCelebration={mutate}
      />

      <CelebrationTab
        channel={channel}
        programLink={programLink}
        celebration={celebration}
        permission={permission}
        mutateCelebration={mutate}
      />
    </ProgramDetailLayout>
  );
}
