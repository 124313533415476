import {
  type DtoChannel,
  type DtoChannelProgramLink,
} from '@lp-lib/api-service-client/public';

import { ProgramActivation } from '../../Program';
import { useBasicProgramFromLink } from '../../Program/usePrograms';

export function TournamentConfiguration(props: {
  channel: DtoChannel;
  programLink: DtoChannelProgramLink;
}): JSX.Element | null {
  const { programLink } = props;
  const { data: program } = useBasicProgramFromLink(programLink);
  if (!program) return null;
  return <ProgramActivation {...props} program={program} />;
}
