import { intlFormat } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import range from 'lodash/range';
import { useMemo } from 'react';
import Select from 'react-select';
import { type ITimezoneOption } from 'react-timezone-select';

import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { ReactTimezoneSelectTypeFixed } from '../ReactTimezoneSelectTypeFixed';

function daySecondsToOption(seconds: number) {
  return {
    label: intlFormat(new Date(0, 0, 0, 0, 0, seconds, 0), {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    }),
    value: seconds,
  };
}

export function CelebrationSendTimePicker(props: {
  sendSeconds: number;
  timezone: string;
  onSendSecondsChange: (value: number) => void;
  onTimezoneChange: (value: string) => void;
}): JSX.Element {
  const { sendSeconds, timezone, onSendSecondsChange, onTimezoneChange } =
    props;

  const sendTimeStyles = useMemo(
    () => buildReactSelectStyles<Option<number>>(),
    []
  );
  const timezoneStyles = useMemo(
    () => buildReactSelectStyles<ITimezoneOption>(),
    []
  );
  const showRecent = useFeatureQueryParam('celebration-send-time-recent');

  const sendTimeOptions = useMemo(() => {
    const seconds = [];
    if (showRecent) {
      const now = utcToZonedTime(new Date(), timezone);
      seconds.push(
        ...range(2, 30, 2).map(
          (min) => (now.getHours() * 60 + now.getMinutes() + min) * 60
        )
      );
    }
    seconds.push(...range(0, 24, 1).map((hour) => hour * 60 * 60));
    return seconds.map((sec) => daySecondsToOption(sec));
  }, [showRecent, timezone]);

  return (
    <div className='w-full flex flex-row gap-4'>
      <Select<Option<number>>
        classNamePrefix='select-box-v2'
        className='w-32'
        styles={sendTimeStyles}
        value={daySecondsToOption(sendSeconds)}
        options={sendTimeOptions}
        onChange={(option) => option && onSendSecondsChange(option.value)}
      />

      <ReactTimezoneSelectTypeFixed
        value={timezone}
        className='select-box text-white flex-1'
        classNamePrefix='select-box-v2'
        styles={timezoneStyles}
        onChange={(option) => option && onTimezoneChange(option.value)}
      />
    </div>
  );
}
