/* eslint-disable react/jsx-no-undef */

import placeholder from '../../../assets/img/placeholder/game-cover.png';
import { apiService } from '../../../services/api-service';
import { RoleUtils } from '../../../types';
import { type IntrosProgramRound } from '../../../types/program';
import { fromMediaDTO } from '../../../utils/api-dto';
import { MediaUtils } from '../../../utils/media';
import { ActionSheet } from '../../ActionSheet';
import { InfoIcon } from '../../icons/InfoIcon';
import { useUser } from '../../UserContext';
import { ProgramRoundEntry } from '../ProgramRoundEntry';
import { type ProgramDetailProps } from '../types';

function IntrosProgramRoundActionSheet(props: { round: IntrosProgramRound }) {
  return (
    <ActionSheet
      btnSizingClassName='w-7.5 h-7.5'
      actions={[
        {
          kind: 'button',
          key: 'repair',
          text: 'Repair',
          icon: <InfoIcon />,
          onClick: () =>
            apiService.program.repairRound(
              props.round.parentId,
              props.round.id
            ),
        },
      ]}
    />
  );
}

export function IntrosProgramRoundList(
  props: ProgramDetailProps & {
    rounds: IntrosProgramRound[];
    timezone: string;
  }
) {
  const { program, rounds, timezone } = props;
  const user = useUser();
  const isAdmin = RoleUtils.isAdmin(user.role);

  const coverSrc =
    MediaUtils.PickMediaUrl(
      fromMediaDTO(program.basicSettings?.cover?.media)
    ) || placeholder;

  return (
    <div className='w-full flex flex-col gap-4'>
      <div className='flex flex-col w-full gap-2'>
        {rounds.map((r) => (
          <ProgramRoundEntry
            key={r.id}
            round={r}
            timezone={timezone}
            // TODO(intros): for better UX, maybe it's better to take the
            // snapshot of the intro message template and save it into the
            // round extensions.
            cover={<img src={coverSrc} alt='cover' />}
            action={
              isAdmin ? <IntrosProgramRoundActionSheet round={r} /> : null
            }
          />
        ))}
      </div>
      {rounds.length === 0 && (
        <div className='w-full flex items-center justify-center text-secondary'>
          No Rounds
        </div>
      )}
    </div>
  );
}
