import { Navigate, Route, Routes } from '@remix-run/react';
import { useMemo } from 'react';

import {
  type DtoBasicProgram,
  type DtoChannel,
  type DtoChannelProgramLink,
} from '@lp-lib/api-service-client/public';

import {
  castProgramLink,
  type WaterCoolerProgramLink,
} from '../../../types/program';
import { useChannelPerm } from '../../Channel';
import { ProgramActivation } from '../ProgramActivation';
import {
  ProgramDetailLayout,
  type ProgramDetailTabItem,
  ProgramDetailTabs,
} from '../ProgramDetailLayout';
import { ProgramMemberList } from '../ProgramMemberList';
import { type ProgramDetailProps } from '../types';
import { useWaterCoolerPastRounds } from './hooks';
import { WaterCoolerProgramDetails } from './WaterCoolerProgramDetails';
import { WaterCoolerProgramPastRounds } from './WaterCoolerProgramPastRounds';

function useTabItems(
  channel: DtoChannel,
  programLink: WaterCoolerProgramLink,
  program: DtoBasicProgram
) {
  const perm = useChannelPerm(channel);
  const { data } = useWaterCoolerPastRounds(programLink.id);
  const numOfPastRounds = data.length;

  return useMemo(() => {
    const tabItems: ProgramDetailTabItem[] = [
      {
        title: program.name || 'Details',
        url: './details',
      },
      {
        title: `Participants(${channel.membersCount})`,
        url: './participants',
      },
      {
        title: `Past Rounds${numOfPastRounds ? `(${numOfPastRounds})` : ''}`,
        url: './rounds',
      },
    ];

    if (perm.managable) {
      tabItems.push({
        title: 'Admin',
        url: `./admin`,
      });
    }
    return tabItems;
  }, [program.name, channel.membersCount, numOfPastRounds, perm.managable]);
}

function ProgramRoutes(props: ProgramDetailProps): JSX.Element {
  return (
    <Routes>
      <Route index element={<Navigate to='./details' replace />} />
      <Route
        path='details'
        element={<WaterCoolerProgramDetails {...props} />}
      />
      <Route
        path='participants'
        element={<ProgramMemberList {...props} managable={false} />}
      />
      <Route
        path='rounds'
        element={<WaterCoolerProgramPastRounds {...props} />}
      />
      <Route path='admin' element={<ProgramActivation {...props} />} />
    </Routes>
  );
}

export function WaterCoolerProgram(props: {
  channel: DtoChannel;
  programLink: DtoChannelProgramLink;
}): JSX.Element {
  const { channel, programLink } = props;
  const basicProgram = programLink.basicProgram;
  if (!basicProgram) throw new Error('Program info is missing.');

  const items = useTabItems(
    channel,
    castProgramLink<WaterCoolerProgramLink>(programLink),
    basicProgram
  );

  return (
    <ProgramDetailLayout {...props} program={basicProgram}>
      <ProgramDetailTabs channelId={channel.id} items={items} />
      <ProgramRoutes
        channel={channel}
        programLink={programLink}
        program={basicProgram}
      />
    </ProgramDetailLayout>
  );
}
