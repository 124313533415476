export function Row(props: {
  icon?: (props: { className?: string }) => JSX.Element;
  title: React.ReactNode;
  description: React.ReactNode;
  left?: React.ReactNode;
  right?: React.ReactNode;
}): JSX.Element {
  const { icon, title, description, left, right } = props;

  return (
    <div className='w-full flex flex-row'>
      <div className='w-1/2 pr-15'>
        <div className='w-full flex flex-row gap-4'>
          <div className='w-7.5 h-7.5 text-3xl'>
            {icon && icon({ className: 'w-full h-full fill-current' })}
          </div>
          <div className='flex-1 flex flex-col gap-1'>
            <h3 className='text-base font-bold'>{title}</h3>
            <p className='text-sms text-icon-gray'>{description}</p>
          </div>
        </div>
        {left && (
          <div className='mt-4 w-full pl-10 flex flex-col gap-8'>{left}</div>
        )}
      </div>
      <div className='w-1/2'>{right}</div>
    </div>
  );
}
