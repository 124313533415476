import { useMemo } from 'react';

import {
  type DtoChannel,
  type DtoChannelProgramLink,
} from '@lp-lib/api-service-client/public';

import { useChannelPerm } from '../Channel';
import { useOrgFeatureContext } from '../Organization';
import { ProgramDetailLayout, ProgramDetailTabs } from '../Program';
import { type ProgramDetailTabItem } from '../Program/ProgramDetailLayout';
import { useBasicProgramFromLink } from '../Program/usePrograms';
import { TournamentDetailRoutes } from './Detail';

function useTabItems(channel: DtoChannel, programLink: DtoChannelProgramLink) {
  const { routePrefix } = useOrgFeatureContext();
  const perm = useChannelPerm(channel);
  return useMemo(() => {
    const base = `${routePrefix}/programs/${programLink.id}`;
    const tabItems: ProgramDetailTabItem[] = [
      {
        title: `Participants(${channel.membersCount})`,
        url: base,
        end: true,
      },
    ];
    if (programLink.programType === 'global_pairing') {
      tabItems.push({
        title: 'Rounds',
        url: `${base}/rounds`,
      });
    }
    if (perm.managable) {
      tabItems.push({
        title: 'Admin',
        url: `${base}/admin`,
      });
    }
    return tabItems;
  }, [
    routePrefix,
    programLink.id,
    programLink.programType,
    channel.membersCount,
    perm.managable,
  ]);
}

export function GlobalPairingProgram(props: {
  channel: DtoChannel;
  programLink: DtoChannelProgramLink;
}): JSX.Element | null {
  const { channel, programLink } = props;
  const items = useTabItems(channel, programLink);
  const { data: basicProgram } = useBasicProgramFromLink(programLink);

  if (!basicProgram) return null;

  return (
    <ProgramDetailLayout {...props} program={basicProgram}>
      <ProgramDetailTabs channelId={channel.id} items={items} />
      <TournamentDetailRoutes channel={channel} programLink={programLink} />
    </ProgramDetailLayout>
  );
}
