import pluralize from 'pluralize';
import { useMemo, useState } from 'react';

import { type DtoChannel } from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';
import { type UpdateCelebrationParticipantRequest } from '../../../services/api-service/celebration.api';
import {
  type Celebration,
  type CelebrationParticipant,
} from '../../../types/celebration';
import {
  ChannelMemberAvatar,
  useTriggerSlackManageUserModal,
} from '../../Channel';
import { CalendarCheckedIcon, CalendarIcon } from '../../icons/CalendarIcon';
import { Loading } from '../../Loading';
import { SwitcherControlled } from '../../Switcher';
import { useUser } from '../../UserContext';
import { CelebrationDateEditor } from '../CelebrationDateEditor';
import {
  CelebrationDatesTemplateDownload,
  CelebrationDatesUploader,
} from '../CelebrationDatesImporter';
import { useCelebrationParticipants } from '../hooks/useCelebrationParticipants';
import { Row } from '../Layout';
import { type CelebrationTabProps } from '../types';
import { CelebrationUtils, useCelebrationTitle } from '../utils';

function InviteMembersBanner(props: {
  channel: DtoChannel;
  membersCount: number;
}) {
  const triggerSlackManageUserModal = useTriggerSlackManageUserModal();

  const handleInvite = () => {
    triggerSlackManageUserModal(props.channel.name);
  };

  return (
    <div className='w-full px-7.5 py-5 bg-red-002 rounded-xl bg-opacity-40 flex justify-between items-center'>
      <div className='flex items-center gap-7.5'>
        <div className='text-4xl'>👻</div>
        <div>
          <h3 className='text-base font-bold'>
            {props.membersCount === 0 && 'This channel is empty!'}
            {props.membersCount === 1 && 'This channel only has one person!'}
          </h3>
          <p className='text-sms font-normal max-w-148'>
            Your teammates need to be members of this channel to be celebrated.
            Once they join, they will appear in the list below.
          </p>
        </div>
      </div>

      <button
        type='button'
        className='btn-primary w-48 h-10'
        onClick={handleInvite}
      >
        Invite teammates
      </button>
    </div>
  );
}

function ImportDatesSection(props: {
  celebration: Celebration;
  participants: CelebrationParticipant[];
  onUpdate: () => void;
}) {
  const { celebration, participants, onUpdate } = props;

  const summary = useMemo(() => {
    const candidates = participants.filter(
      (p) => p.channelMember.status !== 'deactivated'
    );

    const birthdayEnabledParticipants = candidates.filter(
      (p) => p.celebrateBirthday
    );
    const birthdayRecordedParticipants = birthdayEnabledParticipants.filter(
      (p) => p.birthday
    );
    const workerStartDayRecordedParticipants = candidates.filter(
      (p) => !!p.workStartDate
    );

    return {
      birthday: `${birthdayRecordedParticipants.length} of ${pluralize(
        'birthday',
        birthdayEnabledParticipants.length,
        true
      )} recorded`,
      workAnniversary: `${
        workerStartDayRecordedParticipants.length
      } of ${pluralize('work start date', candidates.length, true)} recorded`,
    };
  }, [participants]);

  return (
    <section className='flex flex-col gap-8'>
      <Row
        icon={CalendarIcon}
        title='Import Dates'
        description='Import Birthdays and work start dates with a spreadsheet.'
        right={
          <div className='flex flex-col gap-8'>
            <div>
              <p className='text-sms'>Step 1: Download the template</p>
              <p className='text-sms text-icon-gray'>
                Choose date format for the .csv file.
              </p>
              <div className='mt-3'>
                <CelebrationDatesTemplateDownload participants={participants} />
              </div>
            </div>
            <div>
              <p className='text-sms'>
                Step 2: Fill in your team’s birthdays and work start dates
              </p>
              <p className='text-sms text-icon-gray'>
                Do not change the columns or format, and export as a .csv file.
              </p>
            </div>
            <div>
              <p className='text-sms'>Step 3: Upload your filled out sheet</p>
              <p className='text-sms text-icon-gray'>
                Upload the .csv file with all the dates you want to import.
                (Only .csv file format accepted)
              </p>
              <div className='mt-3'>
                <CelebrationDatesUploader
                  celebration={celebration}
                  onUpdate={onUpdate}
                  className='h-10 px-8 w-[fit-content]'
                />
              </div>
            </div>
          </div>
        }
      />

      <Row
        icon={CalendarCheckedIcon}
        title='Dates Recorded'
        description='Dates imported or added for the team members in your
          Organization’s Luna Park channels. These dates are shared across
          all Birthdays and Celebrations programs.
        '
        right={
          <div className='w-full flex flex-row'>
            <div className='w-1/2 flex flex-col gap-1'>
              <h3 className='text-base font-bold'>🎂 Birthdays</h3>
              <p>{summary.birthday}</p>
            </div>

            <div className='w-1/2 flex flex-col gap-1'>
              <h3 className='text-base font-bold'>💼 Work Anniversaries</h3>
              <p>{summary.workAnniversary}</p>
            </div>
          </div>
        }
      />
    </section>
  );
}

function ParticipantRow(props: {
  channel: DtoChannel;
  participant: CelebrationParticipant;
  editable: boolean;
  onUpdate: (
    updates: Partial<CelebrationParticipant>,
    operation: Promise<unknown>
  ) => void;
}) {
  const { channel, participant, editable, onUpdate } = props;

  const handleUpdateSettings = async (
    req: UpdateCelebrationParticipantRequest
  ) => {
    const op = apiService.celebration.updateParticipantSettings(
      participant.celebrationId,
      participant.uid,
      req
    );
    onUpdate(req, op);
  };

  const handleUpdateBirthday = async (date: Date | null) => {
    const birthday = date ? CelebrationUtils.BirthdayFromDate(date) : null;
    const op = apiService.celebration.updateParticipantBirthday(
      participant.celebrationId,
      participant.uid,
      birthday
    );
    onUpdate({ birthday }, op);
  };

  const handleUpdateWorkStartDate = async (date: Date | null) => {
    const workStartDate = date
      ? CelebrationUtils.WorkStartDateFromDate(date)
      : null;
    const op = apiService.celebration.updateParticipantWorkStartDate(
      participant.celebrationId,
      participant.uid,
      workStartDate
    );
    onUpdate(
      {
        workStartDate,
      },
      op
    );
  };

  return (
    <tr className='h-12'>
      <td>
        <div className='flex items-center'>
          <ChannelMemberAvatar
            exConnectType={channel.exConnectType}
            member={participant.channelMember}
            className='w-9 h-9 mr-2'
          />
          <div className='text-sms'>
            {participant.channelMember.fullName} (
            {participant.channelMember.email})
          </div>
        </div>
      </td>
      <td>
        <div
          className={`text-sms ${
            participant.channelMember.status === 'deactivated'
              ? 'text-red-002'
              : 'text-white'
          }`}
        >
          {participant.channelMember.status === 'deactivated'
            ? 'Deactivated'
            : 'Active'}
          {!!participant.channelMember.orgId &&
            participant.channelMember.orgId !== channel.orgId &&
            `(External)`}
        </div>
      </td>
      <td>
        <CelebrationDateEditor
          value={CelebrationUtils.ParseBirthday(participant.birthday)}
          displayFormat='MMM d'
          optedOut={!participant.celebrateBirthday}
          editable={
            editable && participant.channelMember.status !== 'deactivated'
          }
          modalTitle={`Set ${participant.channelMember.fullName}'s birthday`}
          modalHint={
            <>
              <strong>Don’t worry about the year,</strong> Luna Park doesn’t
              save that information.
            </>
          }
          onChange={handleUpdateBirthday}
        />
      </td>
      {editable && (
        <td>
          {participant.birthday && (
            <SwitcherControlled
              name={`birthday-enabled-${participant.celebrationId}-${participant.uid}`}
              className=''
              checked={participant.celebrateBirthday ?? true}
              onChange={(checked: boolean) =>
                handleUpdateSettings({
                  celebrateBirthday: checked,
                })
              }
            />
          )}
        </td>
      )}
      <td>
        <CelebrationDateEditor
          value={CelebrationUtils.ParseWorkStartDate(participant.workStartDate)}
          displayFormat='MMM d, yyyy'
          optedOut={false}
          editable={
            editable && participant.channelMember.status !== 'deactivated'
          }
          modalTitle={`Set ${participant.channelMember.fullName}’s work start date`}
          onChange={handleUpdateWorkStartDate}
        />
      </td>
    </tr>
  );
}

function YouSettingsSection(props: {
  channel: DtoChannel;
  participants: CelebrationParticipant[];
  onUpdate: (updated: CelebrationParticipant, op: Promise<unknown>) => void;
}) {
  const { channel, participants, onUpdate } = props;

  const user = useUser();
  const me = participants.find((p) => p.uid === user.id);

  if (!me) return null;
  return (
    <section>
      <table className='w-full'>
        <colgroup>
          <col className='w-4/12'></col>
          <col className='w-2/12'></col>
          <col className='w-2/12'></col>
          <col className='w-2/12'></col>
          <col className='w-2/12'></col>
        </colgroup>
        <thead>
          <tr className='text-left'>
            <th className='pb-2'>Your Settings</th>
            <th>Status</th>
            <th>Birthday</th>
            <th>Celebrate B-Day</th>
            <th>Work Start Date</th>
          </tr>
        </thead>
        <tbody>
          <ParticipantRow
            editable={true}
            channel={channel}
            participant={me}
            onUpdate={(updates, op) => {
              const updated = {
                ...me,
                ...updates,
              };
              onUpdate(updated, op);
            }}
          />
        </tbody>
      </table>
    </section>
  );
}

function ParticipantsListSection(props: {
  channel: DtoChannel;
  participants: CelebrationParticipant[];
  editable: boolean;
  onUpdate: (updated: CelebrationParticipant, op: Promise<unknown>) => void;
}) {
  const { channel, participants, editable, onUpdate } = props;

  const [showMissingDates, setShowMissingDates] = useState(false);
  const showParticipants = useMemo(() => {
    const filtered = !showMissingDates
      ? participants
      : participants.filter(
          (p) =>
            p.channelMember.status !== 'deactivated' &&
            (!p.birthday || !p.workStartDate)
        );
    const sorted = filtered.sort((a, b) =>
      a.channelMember.displayName.localeCompare(b.channelMember.displayName)
    );
    return sorted;
  }, [showMissingDates, participants]);

  return (
    <section className='w-full flex flex-col gap-6'>
      {editable && (
        <label className='w-full flex justify-end items-center gap-2'>
          <input
            type={'checkbox'}
            className='checkbox-dark'
            checked={showMissingDates}
            onChange={(event) => setShowMissingDates(event.target.checked)}
          />
          <p className='text-sms'>Only show participants missing dates</p>
        </label>
      )}

      <table className='w-full'>
        <colgroup>
          <col className='w-4/12'></col>
          <col className='w-2/12'></col>
          <col className='w-2/12'></col>
          <col className='w-2/12'></col>
          <col className='w-2/12'></col>
        </colgroup>
        <thead>
          <tr className='text-left'>
            <th className='pb-2'>Name</th>
            <th>Status</th>
            <th>Birthday</th>
            {editable && <th>Celebrate B-Day</th>}
            <th>Work Start Date</th>
          </tr>
        </thead>
        <tbody>
          {showParticipants.map((p) => (
            <ParticipantRow
              key={p.uid}
              editable={editable}
              channel={channel}
              participant={p}
              onUpdate={(updates, op) => {
                const updated = {
                  ...p,
                  ...updates,
                };
                onUpdate(updated, op);
              }}
            />
          ))}
        </tbody>
      </table>

      <div className='w-full text-center text-secondary text-sms'>
        {participants.length === 0
          ? "You don't have any participants to celebrate in this channel."
          : showParticipants.length === 0
          ? '🎉 The dates of all participants have been recorded!'
          : ''}
      </div>
    </section>
  );
}

export function CelebrationTabParticipants(
  props: CelebrationTabProps
): JSX.Element {
  const { channel, celebration, permission } = props;

  useCelebrationTitle({
    tab: 'Participant',
    channel,
  });

  const { participants, isLoading, error, mutate } = useCelebrationParticipants(
    {
      id: celebration.id,
    }
  );

  if (error) {
    return (
      <div className='w-full h-full flex flex-col justify-center items-center gap-3 text-white'>
        <div className='text-2xl font-bold'>Something went wrong!</div>
        <div className='text-lg mb-5'>
          We had trouble loading participants. Please try again.
        </div>
        <button
          type='button'
          className='btn btn-primary w-40 h-10'
          onClick={() => mutate()}
        >
          Retry
        </button>
      </div>
    );
  }
  if (isLoading) return <Loading />;

  const handleUpdate = (
    updated: CelebrationParticipant,
    op: Promise<unknown>
  ) => {
    const next = [...participants];
    const index = next.findIndex((p) => p.uid === updated.uid);
    if (index === -1) return;
    next.splice(index, 1, updated);

    mutate(
      async () => {
        await op;
        return next;
      },
      {
        optimisticData: next,
      }
    );
  };

  return (
    <div className='text-white w-full flex flex-col gap-8'>
      <section className='w-full'>
        <p className='text-xs font-normal text-secondary'>
          This participant list is synchronized with your Slack channel.
        </p>
        {permission.inviteMembers && participants.length <= 1 && (
          <div className='mt-4 w-full'>
            <InviteMembersBanner
              channel={channel}
              membersCount={participants.length}
            />
          </div>
        )}
      </section>

      {permission.editable && (
        <ImportDatesSection
          celebration={celebration}
          participants={participants}
          onUpdate={() => mutate()}
        />
      )}

      {!permission.editable && (
        <YouSettingsSection
          channel={channel}
          participants={participants}
          onUpdate={handleUpdate}
        />
      )}

      <hr className='border-lp-gray-002' />

      <ParticipantsListSection
        channel={channel}
        participants={participants}
        editable={permission.editable}
        onUpdate={handleUpdate}
      />
    </div>
  );
}
