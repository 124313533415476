import { CelebrationMessageFrequency } from '../../types/celebration';
import { SwitcherControlled } from '../Switcher';

function MessageFrequencyCard(props: {
  title: string;
  description: string;
  selected: boolean;
  onSelect: () => void;
  children?: React.ReactNode;
}) {
  return (
    <label
      className='w-full p-4 border border-secondary rounded-xl flex items-start gap-4 hover:cursor-pointer'
      // onClick={props.onSelect}
    >
      <input
        type={'radio'}
        checked={props.selected}
        className='field-radio w-4 h-4'
        onChange={(e) => {
          e.stopPropagation();
          props.onSelect();
        }}
      />

      <div className='flex-1'>
        <h3 className='text-sms font-bold'>{props.title}</h3>
        <p className='text-sms text-icon-gray'>{props.description}</p>
        {props.children}
      </div>
    </label>
  );
}

export function MessageFrequencySelect(props: {
  frequency: CelebrationMessageFrequency;
  groupByCelebrationType: boolean;
  onChange: (
    frequency: CelebrationMessageFrequency,
    groupByCelebrationType: boolean
  ) => void;
}): JSX.Element {
  const { frequency, groupByCelebrationType, onChange } = props;

  return (
    <div className='w-full flex flex-col gap-2.5'>
      <MessageFrequencyCard
        title='Daily (the day of)'
        description='Messages will be sent every day there is a celebration. Weekend
        celebrations will be sent the Friday before.'
        selected={frequency === CelebrationMessageFrequency.Daily}
        onSelect={() => onChange(CelebrationMessageFrequency.Daily, true)}
      >
        {frequency === CelebrationMessageFrequency.Daily ? (
          <div className='mt-3 flex flex-row justify-between'>
            <div>
              <h3 className='text-sms font-bold'>
                Group messages by celebration type
              </h3>
              <p className='text-2xs text-icon-gray'>
                (Recommended for larger channels)
              </p>
            </div>
            <div className='w-9 h-5' onClick={(e) => e.stopPropagation()}>
              <SwitcherControlled
                name={`celebration-message-group-by-type`}
                checked={groupByCelebrationType}
                onChange={(checked: boolean) =>
                  onChange(CelebrationMessageFrequency.Daily, checked)
                }
              />
            </div>
          </div>
        ) : null}
      </MessageFrequencyCard>

      <MessageFrequencyCard
        title='Weekly'
        description='Messages will be grouped by celebration type and will be sent on Mondays.'
        selected={frequency === CelebrationMessageFrequency.Weekly}
        onSelect={() => onChange(CelebrationMessageFrequency.Weekly, true)}
      />

      <MessageFrequencyCard
        title='Monthly'
        description='Messages will be grouped by celebration type and will be sent on the first work day of every month.'
        selected={frequency === CelebrationMessageFrequency.Monthly}
        onSelect={() => onChange(CelebrationMessageFrequency.Monthly, true)}
      />
    </div>
  );
}
