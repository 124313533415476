import { Navigate, Route, Routes } from '@remix-run/react';
import { useMemo } from 'react';

import {
  type DtoBasicProgram,
  type DtoChannel,
  type DtoChannelProgramLink,
} from '@lp-lib/api-service-client/public';

import {
  type CalendarProgramLink,
  castProgramLink,
} from '../../../types/program';
import { useChannelPerm } from '../../Channel';
import { ProgramActivation } from '../ProgramActivation';
import {
  ProgramDetailLayout,
  type ProgramDetailTabItem,
  ProgramDetailTabs,
} from '../ProgramDetailLayout';
import { ProgramMemberList } from '../ProgramMemberList';
import { type ProgramDetailProps } from '../types';
import { CalendarPastRounds } from './CalendarPastRounds';
import { CalendarProgramDetails } from './CalendarProgramDetails';

function useTabItems(
  channel: DtoChannel,
  programLink: CalendarProgramLink,
  program: DtoBasicProgram
) {
  const perm = useChannelPerm(channel);
  return useMemo(() => {
    const tabItems: ProgramDetailTabItem[] = [
      {
        title: program.name || 'Details',
        url: './details',
      },
      {
        title: `Participants(${channel.membersCount})`,
        url: './participants',
      },
      {
        title: `Past ${program.name ?? 'Rounds'}(${
          programLink.extensions?.engagedRoundIds?.length ?? 0
        })`,
        url: './rounds',
      },
    ];
    if (perm.managable) {
      tabItems.push({
        title: 'Admin',
        url: `./admin`,
      });
    }
    return tabItems;
  }, [
    program.name,
    channel.membersCount,
    programLink.extensions?.engagedRoundIds?.length,
    perm.managable,
  ]);
}

function CalendarRoutes(props: ProgramDetailProps): JSX.Element {
  return (
    <Routes>
      <Route index element={<Navigate to='./details' replace />} />
      <Route path='details' element={<CalendarProgramDetails {...props} />} />
      <Route
        path='participants'
        element={<ProgramMemberList {...props} managable={false} />}
      />
      <Route path='rounds' element={<CalendarPastRounds {...props} />} />
      <Route path='admin' element={<ProgramActivation {...props} />} />
    </Routes>
  );
}

export function CalendarProgram(props: {
  channel: DtoChannel;
  programLink: DtoChannelProgramLink;
}): JSX.Element {
  const { channel, programLink } = props;
  const basicProgram = programLink.basicProgram;
  if (!basicProgram) throw new Error('Program info is missing.');

  const items = useTabItems(
    channel,
    castProgramLink<CalendarProgramLink>(programLink),
    basicProgram
  );

  return (
    <ProgramDetailLayout {...props} program={basicProgram}>
      <ProgramDetailTabs channelId={channel.id} items={items} />
      <CalendarRoutes
        channel={channel}
        programLink={programLink}
        program={basicProgram}
      />
    </ProgramDetailLayout>
  );
}
