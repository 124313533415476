import { useNavigate } from '@remix-run/react';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useTitle } from 'react-use';

import { type DtoChannel } from '@lp-lib/api-service-client/public';

import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import {
  GlobalPairingRoundStatus,
  type PairingRound,
  PairingRoundStatus,
} from '../../../types';
import { err2s, makeTitle } from '../../../utils/common';
import { ChannelUtils, useChannelPerm } from '../../Channel';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ArrowRightIcon } from '../../icons/Arrows';
import { Loading } from '../../Loading';
import { useOrgFeatureContext } from '../../Organization';
import { useRounds } from '../../Pairing/Round/CRUD';

function RoundItem(props: { round: PairingRound }): JSX.Element {
  const { round } = props;
  const { org, adminMode } = useOrgFeatureContext();
  const navigate = useNavigate();

  const handleClick = () => {
    if (adminMode) {
      navigate(
        `/admin/organizations/${org.id}/pairing-rounds/${round.id}/details`
      );
    } else {
      navigate(`/leaderboards/${round.id}`);
    }
  };

  return (
    <div
      className='text-sms h-8 border border-secondary rounded-xl flex items-center px-2 hover:bg-lp-gray-002 cursor-pointer'
      onClick={handleClick}
    >
      <div className='w-1/4'>
        {format(new Date(round.startedAt), 'MM/dd/yyyy')} -{' '}
        {format(new Date(round.endedAt), 'MM/dd/yyyy')}
      </div>
      <div className='w-1/4'>
        <span
          className={`${
            round.globalRound?.status !== GlobalPairingRoundStatus.InProgress
              ? 'text-icon-gray'
              : ''
          }`}
        >
          {round.status === PairingRoundStatus.Paused
            ? 'Opted out'
            : round.globalRound?.status || 'N/A'}
        </span>
      </div>
      <div className='w-1/4'>
        {round.mainGamePack ? (
          round.mainGamePack.name
        ) : (
          <span className='text-red-002'>ERROR</span>
        )}
      </div>
      <div className='flex items-center justify-end flex-grow'>
        <ArrowRightIcon />
      </div>
    </div>
  );
}

function NextRoundCheck(props: {
  channel: DtoChannel;
  nextRound: PairingRound | undefined;
  onComplete: () => void;
}): JSX.Element | null {
  const { channel, nextRound, onComplete } = props;
  const [op, setOp] = useState<'none' | 'opted-out' | 'dismissed'>('none');
  const perm = useChannelPerm(channel);
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();
  const [roundStatus] = useState(nextRound?.status);

  const {
    state: { transformed: state },
    error,
    call: optOut,
  } = useAsyncCall(
    useCallback(async (roundId: string) => {
      return apiService.pairing.updateRound(roundId, {
        status: PairingRoundStatus.Paused,
      });
    }, [])
  );

  if (!nextRound) return null;
  if (roundStatus === PairingRoundStatus.Paused) return null;
  if (op === 'dismissed') return null;
  const pausable =
    nextRound.globalRound?.status === GlobalPairingRoundStatus.Scheduled ||
    nextRound.globalRound?.status === GlobalPairingRoundStatus.InProgress;
  if (!pausable) return null;
  if (!(perm.isAdmin || perm.isChannelCreator)) return null;
  const roundStarted =
    nextRound.globalRound?.status === GlobalPairingRoundStatus.InProgress;

  const handleOptOut = async () => {
    const confirmed = await confirmCancel({
      kind: 'confirm-cancel',
      prompt: (
        <div className='flex flex-col item-center justify-center gap-6'>
          <div className='text-2xl font-medium text-white text-center'>
            Are you sure you want to opt out the #{channel.name} channel from
            this round?
          </div>
          <div className='text-sms text-white text-center'>
            You won’t be able to opt back in until the next Global Pairing
            Tournament round.
          </div>
        </div>
      ),
      confirmBtnLabel: 'Confirm',
      confirmBtnVariant: 'delete',
      cancelBtnLabel: 'Cancel',
      containerClassName: 'bg-black bg-opacity-60',
      boxDimensionsClassName: 'w-86 h-70 px-4 py-2',
    });
    if (confirmed.result === 'canceled') return;
    const resp = await optOut(nextRound.id);
    if (!resp) return;
    setOp('opted-out');
    onComplete();
  };

  const containerClassName =
    'w-full h-12 bg-[#8C6FFF] bg-opacity-40 rounded-lg px-4 mb-4 flex items-center';

  if (op === 'opted-out') {
    return (
      <div className={`${containerClassName} justify-center relative`}>
        <div>You’ve opted out of the Round</div>
        <button
          type='button'
          className='btn text-primary font-bold right-4 absolute'
          onClick={() => setOp('dismissed')}
        >
          Dismiss
        </button>
      </div>
    );
  }

  return (
    <div className={`${containerClassName} justify-between`}>
      <div className='text-[#8C6FFF] font-medium'>
        {roundStarted
          ? 'Current round is live! It’ll run from'
          : 'Next round is kicking off on'}{' '}
        {format(new Date(nextRound.startedAt), 'MM/dd/yy')} -{' '}
        {format(new Date(nextRound.endedAt), 'MM/dd/yy')}
      </div>
      <div className='flex flex-col items-end justify-center'>
        <div className='flex item-center gap-4'>
          <div className='text-[#8C6FFF] font-medium'>Not a good time?</div>
          {state.isRunning ? (
            <Loading text='' imgClassName='w-5 h-5' />
          ) : (
            <button
              type='button'
              className='btn text-primary font-bold'
              onClick={handleOptOut}
            >
              Opt out your channel
            </button>
          )}
        </div>
        {error && <div className='text-red-002 text-sms'>{err2s(error)}</div>}
      </div>
    </div>
  );
}

function RoundList(props: { channel: DtoChannel }): JSX.Element {
  const { channel } = props;
  const { rounds, isLoading, error, reload } = useRounds({
    channelId: channel.id,
    orderBy: 'startedAt',
  });

  if (isLoading) return <Loading />;
  if (error) return <div className='text-red-002'>{err2s(error)}</div>;
  if (!rounds?.length)
    return (
      <div className='text-secondary text-sms'>No rounds in this channel</div>
    );

  const isValidRound = (r: PairingRound): boolean => {
    return (
      r.globalRound?.status === GlobalPairingRoundStatus.Scheduled ||
      r.globalRound?.status === GlobalPairingRoundStatus.InProgress ||
      r.globalRound?.status === GlobalPairingRoundStatus.Completed
    );
  };

  const validRounds = rounds.filter(isValidRound);

  return (
    <div className='w-full'>
      <NextRoundCheck
        channel={channel}
        nextRound={validRounds[0]}
        onComplete={() => reload()}
      />
      <header className='w-full flex items-center px-2 font-bold'>
        <div className='w-1/4'>Start/End Dates</div>
        <div className='w-1/4'>Status</div>
        <div className='w-1/4'>Game Pack</div>
        <div></div>
      </header>
      <section className='w-full flex flex-col mt-2'>
        {validRounds.map((r) => (
          <RoundItem key={r.id} round={r} />
        ))}
      </section>
    </div>
  );
}

export function TournamentRounds(props: { channel: DtoChannel }): JSX.Element {
  const { channel } = props;
  useTitle(makeTitle(`Rounds | ${ChannelUtils.ChannelName(channel)}`));
  return (
    <div className='w-full flex items-center justify-center text-white mt-8'>
      <RoundList channel={channel} />
    </div>
  );
}
