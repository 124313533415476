import { useMemo } from 'react';
import useSWR from 'swr';

import { apiService } from '../../../services/api-service';
import { type CelebrationEvent } from '../../../types/celebration';

interface useCelebrationUpcomingEventsReturned {
  events: CelebrationEvent[];
  isLoading: boolean;
  error?: Error | null;
  refresh: () => void;
}

export function useCelebrationUpcomingEvents(props: {
  id: string;
  days: number;
}): useCelebrationUpcomingEventsReturned {
  const { data, error, isValidating, mutate } = useSWR(
    props.id ? [`/celebrations/${props.id}/events/upcoming`, props.days] : null,
    async () =>
      (await apiService.celebration.getUpcomingEvents(props.id, props.days))
        .data.events
  );

  return useMemo(
    () => ({
      events: data || [],
      isLoading: !data && isValidating,
      error,
      refresh: async () => {
        return await mutate();
      },
    }),
    [data, isValidating, error, mutate]
  );
}
