import { useSearchParams } from '@remix-run/react';
import { useNavigate } from '@remix-run/react';
import { useEffect } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useTitle } from 'react-use';
import { useSWRConfig } from 'swr';

import { type DtoTag } from '@lp-lib/api-service-client/public';

import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useBlockLeave } from '../../../hooks/useUnload';
import { apiService } from '../../../services/api-service';
import {
  type CalendarProgramRound,
  castProgramRounds,
} from '../../../types/program';
import { err2s, makeTitle } from '../../../utils/common';
import { Loading } from '../../Loading';
import { useOrgFeatureContext } from '../../Organization';
import { ProgramCategoriesSubscriber } from '../ProgramCategories';
import { SubmitActions, useUpdateTabRightNode } from '../ProgramDetailLayout';
import { type ProgramDetailProps } from '../types';
import { useProgramRounds } from '../usePrograms';
import { resetFormAndGoBack } from '../utils';
import {
  CalendarProgramRoundDetail,
  CalendarProgramRoundList,
} from './CalendarProgramRound';

type FormData = {
  selectedTagIds: number[];
};

function CategoryEditor(
  props: ProgramDetailProps & {
    tags: DtoTag[];
  }
) {
  const { control } = useFormContext<FormData>();
  const { tags } = props;

  return (
    <Controller
      name='selectedTagIds'
      control={control}
      render={({ field: { value, onChange } }) => (
        <ProgramCategoriesSubscriber
          tags={tags}
          selectedTagIds={value}
          onAdd={(tag) => {
            onChange([...value, tag.id]);
          }}
          onRemove={(tag) => {
            onChange(value.filter((id) => id !== tag.id));
          }}
          hint="Hit the plus button to be notified about holidays in your team's provide country."
        />
      )}
    />
  );
}

function Container(props: ProgramDetailProps) {
  const { program, programLink } = props;
  const { data, isLoading, error } = useProgramRounds(program.id);
  const [searchParams, setSearchParams] = useSearchParams();
  const timezone = useInstance(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const { routePrefix } = useOrgFeatureContext();
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const mutateAndGoBack = () => {
    mutate(`/channels/program-links/${programLink.id}`);
    navigate(`${routePrefix}/channels`);
  };

  const form = useForm<FormData>({
    defaultValues: {
      selectedTagIds: programLink.tagSettings?.selectedTagIds ?? [],
    },
  });

  const onSave = useLiveCallback(async () => {
    await form.handleSubmit(async (data) => {
      await apiService.channel.updateProgramLink(
        programLink.channelId,
        programLink.id,
        {
          tagSettings: {
            selectedTagIds: data.selectedTagIds,
          },
        }
      );
    })();
    resetFormAndGoBack(form, mutateAndGoBack);
  });
  const onCancel = useLiveCallback(() =>
    resetFormAndGoBack(form, mutateAndGoBack)
  );

  const { isSubmitting, isDirty } = form.formState;

  const updateTabRightNode = useUpdateTabRightNode();
  useEffect(() => {
    updateTabRightNode(
      <SubmitActions
        isValid
        isSubmitting={isSubmitting}
        onSave={onSave}
        onCancel={onCancel}
      />
    );
    return () => updateTabRightNode(null);
  }, [isSubmitting, onCancel, onSave, updateTabRightNode]);

  useBlockLeave(() => !isDirty);

  if (isLoading) return <Loading text='' />;
  if (error) return <div className='text-sms text-red-002'>{err2s(error)}</div>;
  const rounds = castProgramRounds<CalendarProgramRound>(data ?? []);
  const allTags = rounds.flatMap((r) => r.tags ?? []);
  const roundId = searchParams.get('roundId');
  const round = roundId ? rounds.find((r) => r.id === roundId) : null;
  const selectedTagIds = form.watch('selectedTagIds');
  const filteredRounds = rounds
    .filter((r) => {
      if (selectedTagIds.length === 0) return false;
      if (!r.tags) return false;
      // maybe also include _inProgress_ round?
      if (r.status !== 'scheduled') return false;
      return r.tags.some((t) => selectedTagIds.includes(t.id));
    })
    .sort((a, b) => a.startedAt?.localeCompare(b.startedAt ?? '') ?? 0);

  if (round)
    return (
      <CalendarProgramRoundDetail
        {...props}
        round={round}
        timezone={timezone}
        onBack={() => setSearchParams()}
      />
    );

  return (
    <div className='w-full flex flex-col gap-8'>
      <FormProvider {...form}>
        <CategoryEditor {...props} tags={allTags} />
      </FormProvider>
      <CalendarProgramRoundList
        {...props}
        rounds={filteredRounds}
        timezone={timezone}
        onClick={(r) => setSearchParams({ roundId: r.id })}
      />
    </div>
  );
}

export function CalendarProgramDetails(props: ProgramDetailProps) {
  useTitle(makeTitle(`${props.program.name || 'Details'}`));
  return (
    <div className='w-full flex items-center justify-center text-white mt-8'>
      <Container {...props} />
    </div>
  );
}
