import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { type DtoChannel } from '@lp-lib/api-service-client/public';

import { getFeatureQueryParamNumber } from '../../../hooks/useFeatureQueryParam';
import {
  type Celebration,
  type CelebrationEvent,
  CelebrationEventType,
} from '../../../types/celebration';
import { assertExhaustive } from '../../../utils/common';
import { ChannelMemberAvatar } from '../../Channel';
import { Loading } from '../../Loading';
import { CelebrationEventKnifeUtils } from '../CelebrationEventUtils';
import { useCelebrationUpcomingEvents } from '../hooks/useCelebrationEvents';
import { type CelebrationTabProps } from '../types';
import { useCelebrationTitle } from '../utils';

function EventDetail(props: {
  event: CelebrationEvent;
  channel: DtoChannel;
  celebration: Celebration;
}) {
  const { channel, event, celebration } = props;

  switch (event.type) {
    case CelebrationEventType.Birthday:
    case CelebrationEventType.WorkAnniversary:
      return (
        <div className='py-4 flex flex-col gap-2'>
          {event.targets.map((target) => (
            <div key={target.participant.uid} className='flex items-center'>
              <ChannelMemberAvatar
                exConnectType={channel.exConnectType}
                member={target.participant.channelMember}
                className='w-9 h-9 mr-2'
              />
              <div className='text-sms'>
                <p>
                  {target.participant.channelMember.fullName} (
                  {target.participant.channelMember.email})
                </p>
                <p className='text-icon-gray text-xs'>
                  {format(
                    utcToZonedTime(
                      new Date(target.localRealTime),
                      celebration.messageSettings.timezone
                    ),
                    'eeee, MMM d'
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
      );
    default:
      assertExhaustive(event.type);
      return null;
  }
}

function EventsListSection(props: {
  days: number;
  channel: DtoChannel;
  celebration: Celebration;
  events: CelebrationEvent[];
}) {
  const { days, channel, celebration, events } = props;

  return (
    <section>
      <table className='w-full'>
        <colgroup>
          <col className='w-12'></col>
          <col className='w-70'></col>
          <col></col>
        </colgroup>
        <thead>
          <tr className='text-left font-medium h-10 border-b border-lp-gray-003'>
            <th></th>
            <th>Message Date</th>
            <th>Celebrating</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event, index) => (
            <tr key={index} className='border-b border-lp-gray-003'>
              <td>
                {CelebrationEventKnifeUtils.Icon(event.type, {
                  className: 'text-3xl',
                })}
              </td>
              <td>
                {format(
                  utcToZonedTime(
                    new Date(event.sendTime),
                    celebration.messageSettings.timezone
                  ),
                  'eeee, MMM d'
                )}
              </td>
              <td>
                <EventDetail
                  event={event}
                  channel={channel}
                  celebration={celebration}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {events.length === 0 && (
        <div className='mt-10 w-full text-center'>
          <p className='text-base'>
            There are no celebrations in the next {days} days.
          </p>
          <p className='text-sms text-icon-gray'>
            You can add and edit celebration dates on the "Participants" tab.
          </p>
        </div>
      )}
    </section>
  );
}

export function CelebrationUpcomingTab(
  props: CelebrationTabProps
): JSX.Element {
  const { channel, celebration } = props;

  useCelebrationTitle({
    tab: 'Upcoming',
    channel,
  });

  const days = getFeatureQueryParamNumber('celebration-upcoming-days');

  const { events, error, isLoading, refresh } = useCelebrationUpcomingEvents({
    id: celebration.id,
    days,
  });

  if (error) {
    return (
      <div className='w-full flex-1 flex flex-col justify-center items-center gap-3 text-white'>
        <div className='text-2xl font-bold'>Something went wrong!</div>
        <div className='text-lg mb-5'>
          We had trouble loading celebrations. Please try again.
        </div>
        <button
          type='button'
          className='btn btn-primary w-40 h-10'
          onClick={refresh}
        >
          Retry
        </button>
      </div>
    );
  }
  if (isLoading) return <Loading />;

  return (
    <div className='w-full text-white flex flex-col gap-10'>
      <section className='flex gap-4'>
        <p className='text-2xl'>🎉</p>
        <div>
          <p className='text-base font-bold'>Upcoming Celebrations</p>
          <p className='text-sms font-normal text-icon-gray'>
            Celebration messages planned to go out in the next {days} days
          </p>
        </div>
      </section>

      <EventsListSection
        days={days}
        channel={channel}
        celebration={celebration}
        events={events}
      />
    </div>
  );
}
