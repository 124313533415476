import { useSearchParams } from '@remix-run/react';
import { useTitle } from 'react-use';

import { useInstance } from '../../../hooks/useInstance';
import {
  type CalendarProgramLink,
  type CalendarProgramRound,
  castProgramLink,
  castProgramRounds,
} from '../../../types/program';
import { err2s, makeTitle } from '../../../utils/common';
import { Loading } from '../../Loading';
import { type ProgramDetailProps } from '../types';
import { useProgramRounds } from '../usePrograms';
import {
  CalendarProgramRoundDetail,
  CalendarProgramRoundList,
} from './CalendarProgramRound';

function Container(props: ProgramDetailProps) {
  const { program } = props;
  const programLink = castProgramLink<CalendarProgramLink>(props.programLink);
  const { data, isLoading, error } = useProgramRounds(program.id);
  const [searchParams, setSearchParams] = useSearchParams();
  const timezone = useInstance(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  if (isLoading) return <Loading text='' />;
  if (error) return <div className='text-sms text-red-002'>{err2s(error)}</div>;
  const rounds = castProgramRounds<CalendarProgramRound>(data ?? []);
  const roundId = searchParams.get('roundId');
  const round = roundId ? rounds.find((r) => r.id === roundId) : null;

  const filteredRounds = rounds.filter(
    (r) => !!programLink.extensions?.engagedRoundIds?.includes(r.id)
  );

  if (round)
    return (
      <CalendarProgramRoundDetail
        {...props}
        round={round}
        timezone={timezone}
        onBack={() => setSearchParams()}
      />
    );

  return (
    <CalendarProgramRoundList
      {...props}
      rounds={filteredRounds}
      timezone={timezone}
      onClick={(r) => setSearchParams({ roundId: r.id })}
    />
  );
}

export function CalendarPastRounds(props: ProgramDetailProps) {
  useTitle(makeTitle(`Past ${props.program.name || 'Rounds'}`));
  return (
    <div className='w-full flex items-center justify-center text-white mt-8'>
      <Container {...props} />
    </div>
  );
}
