import { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { type ModelsMessageTemplateV2 } from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { type MessageVars } from './MessageCampaign';
import { MessageTemplatePreview } from './MessageTemplate';
import { MessageTemplateButtonsEditor } from './MessageTemplateButton';
import { MessageTemplateMediaEditor } from './MessageTemplateMedia';
import { MessageTemplateTextEditor } from './MessageTemplateText';

type FormData = {
  template: ModelsMessageTemplateV2;
};

type EditorProps = {
  template: ModelsMessageTemplateV2;
  vars: MessageVars['text'];
  mediaVars: MessageVars['media'];
  header?: React.ReactNode;
  mediaEditable?: boolean;
  buttonEditable?: boolean;
  onSave: (template: ModelsMessageTemplateV2) => void;
  onCancel?: () => void;
  saveButtonText?: string;
};

function MessageTemplateEditorModal(props: EditorProps) {
  const { vars, mediaVars, header } = props;
  const form = useForm<FormData>({
    defaultValues: {
      template: props.template,
    },
  });
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const template = form.watch('template');
  const { append, remove, move, update } = useFieldArray({
    control: form.control,
    name: 'template.buttons',
    keyName: 'key',
  });

  const onSave = form.handleSubmit((data) => {
    props.onSave(data.template);
  });

  return (
    <ModalWrapper containerClassName='w-240' borderStyle='gray'>
      <section className='p-7.5'>
        <header className='text-2xl font-medium'>
          {header || 'Edit Message Template'}
        </header>
        <main className='mt-5 w-full flex gap-4'>
          <div className='w-1/2 flex flex-col gap-5'>
            <Controller
              name='template.text'
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <MessageTemplateTextEditor
                  vars={vars}
                  text={value || ''}
                  onChange={onChange}
                  error={fieldState.error}
                />
              )}
              rules={{ required: true, minLength: 1, maxLength: 2000 }}
            />

            {props.mediaEditable && (
              <Controller
                name='template.media'
                control={form.control}
                render={({ field: { value, onChange } }) => (
                  <MessageTemplateMediaEditor
                    media={value}
                    vars={mediaVars}
                    onChange={onChange}
                    setIsUploading={setIsUploading}
                  />
                )}
              />
            )}

            {props.buttonEditable && (
              <Controller
                name='template.buttons'
                control={form.control}
                render={({ field: { value } }) => (
                  <MessageTemplateButtonsEditor
                    buttons={value || []}
                    onAdd={append}
                    onDelete={(index) => remove(index)}
                    onChange={(index, updates) => {
                      if (!value) return null;
                      update(index, {
                        ...value[index],
                        ...updates,
                      });
                    }}
                    onMove={move}
                  />
                )}
              />
            )}
          </div>

          <div className='w-1/2'>
            <div className='mb-2 flex justify-between items-center'>
              <div className='font-bold'>Preview</div>
            </div>
            <MessageTemplatePreview
              template={template}
              vars={vars}
              mediaVars={mediaVars}
              className='min-h-72'
            />
          </div>
        </main>
        <footer className='flex items-center gap-10'>
          <button type='button' className='btn' onClick={() => form.reset()}>
            Reset to Default
          </button>
          <div className='ml-auto'></div>
          <button type='button' className='btn' onClick={props.onCancel}>
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary h-10 px-4 min-w-30'
            onClick={onSave}
            disabled={isUploading}
          >
            {props.saveButtonText ?? 'Save'}
          </button>
        </footer>
      </section>
    </ModalWrapper>
  );
}

export function useTriggerMessageTemplateEditorModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useLiveCallback((props: EditorProps) => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <MessageTemplateEditorModal
          {...props}
          onCancel={() => {
            props.onCancel?.();
            p.internalOnCancel();
          }}
          onSave={(template) => {
            props.onSave(template);
            p.internalOnConfirm();
          }}
        />
      ),
    });
  });
}
