import startCase from 'lodash/startCase';

import { apiService } from '../../../services/api-service';
import {
  type Celebration,
  type CelebrationEventMessageSettings,
  CelebrationEventType,
  type CelebrationMessageSettings,
} from '../../../types/celebration';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { TimerIcon } from '../../icons/TimerIcon';
import { SwitcherControlled } from '../../Switcher';
import { CelebrationEventKnifeUtils } from '../CelebrationEventUtils';
import { MessageFrequencySelect } from '../CelebrationFrequencySelect';
import { CelebrationSendTimePicker } from '../CelebrationSendTimePicker';
import {
  CelebrationMessageEditModal,
  CelebrationMessagePreview,
} from '../CelebrationSlackMessage';
import { Row } from '../Layout';
import { type CelebrationTabProps } from '../types';
import { useCelebrationTitle } from '../utils';

interface SharedProps {
  celebration: Celebration;
  messageSettings: CelebrationMessageSettings;
  onChange: (updates: Partial<CelebrationMessageSettings>) => void;
}

function MessageSendSection(props: SharedProps) {
  const { messageSettings, onChange } = props;

  return (
    <section className='w-full flex flex-col gap-8'>
      <Row
        icon={TimerIcon}
        title='Message Send Time'
        description='Luna Park will send celebration messages at approximately this
        time in the chosen time zone.'
        right={
          <CelebrationSendTimePicker
            sendSeconds={messageSettings.sendSeconds}
            timezone={messageSettings.timezone}
            onSendSecondsChange={(sendSeconds) =>
              onChange({
                sendSeconds,
              })
            }
            onTimezoneChange={(timezone) => onChange({ timezone })}
          />
        }
      />

      <Row
        title='Message Cadence'
        description='Luna Park can send message daily, weekly, or monthly to tailor to
        different team sizes and preferences.'
        right={
          <MessageFrequencySelect
            frequency={messageSettings.frequency}
            groupByCelebrationType={messageSettings.groupByCelebrationType}
            onChange={(frequency, groupByCelebrationType) =>
              onChange({ frequency, groupByCelebrationType })
            }
          />
        }
      />
    </section>
  );
}

function EventMessageSettingsSection(
  props: SharedProps & {
    type: CelebrationEventType;
  }
) {
  const { celebration, messageSettings, onChange, type } = props;

  const label = CelebrationEventKnifeUtils.Label(type);
  const { key, value: settings } = CelebrationEventKnifeUtils.MessageSettings(
    type,
    messageSettings
  );

  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleUpdate = (updates: Partial<CelebrationEventMessageSettings>) => {
    onChange({
      [key]: {
        ...settings,
        ...updates,
      },
    });
  };

  const handleOpenCustomizeMessageModal = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <CelebrationMessageEditModal
          type={type}
          messageSettings={messageSettings}
          settings={settings}
          onCancel={p.internalOnCancel}
          onSave={(message) => {
            handleUpdate({
              customizedMessage: message,
            });
            p.internalOnConfirm();
          }}
        />
      ),
    });
  };

  return (
    <section className='w-full flex flex-col gap-8'>
      <Row
        icon={(props) => CelebrationEventKnifeUtils.Icon(type, props)}
        title={`Automated ${startCase(label)} Messages`}
        description={`Have Luna Park send ${label} messages to the selected channel
        every time there is a ${label}.`}
        right={
          <div className='flex items-center gap-3'>
            <SwitcherControlled
              name={`celebration-${celebration.id}-message-settings-${type}-enabled`}
              checked={settings.enabled}
              onChange={(checked) => handleUpdate({ enabled: checked })}
            />
            <p className='text-sms'>Send automatic {label} messages</p>
          </div>
        }
      />

      <Row
        title={`${startCase(label)} Message`}
        description={`The ${label} celebration message that Luna Park sends to the channel`}
        left={
          <>
            <button
              className='btn-secondary w-55 h-10'
              onClick={handleOpenCustomizeMessageModal}
            >
              Customize Message
            </button>

            <div className='flex flex-row gap-3'>
              <div className='w-9 h-5'>
                <SwitcherControlled
                  name={`celebration-${celebration.id}-message-settings-${type}-include-media`}
                  checked={settings.includeMedia}
                  onChange={(checked: boolean) =>
                    handleUpdate({
                      includeMedia: checked,
                    })
                  }
                />
              </div>
              <p className='text-sms font-normal text-icon-gray'>
                <strong className='text-white'>
                  Include media in your message
                </strong>{' '}
                (Luna Park will cycle through different types of media to send
                with your message)
              </p>
            </div>
          </>
        }
        right={
          <CelebrationMessagePreview
            messageSettings={messageSettings}
            type={type}
            settings={settings}
          />
        }
      />
    </section>
  );
}

export function CelebrationTabMessages(
  props: CelebrationTabProps
): JSX.Element {
  const { channel, celebration, mutateCelebration } = props;

  useCelebrationTitle({
    tab: 'Messages',
    channel,
  });

  const handleUpdate = async (updates: Partial<CelebrationMessageSettings>) => {
    const messageSettings = {
      ...celebration.messageSettings,
      ...updates,
    };
    const updated = {
      ...celebration,
      messageSettings,
    };
    mutateCelebration(
      async () => {
        await apiService.celebration.updateMessageSettings(celebration.id, {
          messageSettings,
        });
        return updated;
      },
      {
        optimisticData: updated,
      }
    );
  };

  return (
    <div className='w-full text-white flex flex-col gap-8'>
      <MessageSendSection
        celebration={celebration}
        messageSettings={celebration.messageSettings}
        onChange={handleUpdate}
      />

      <hr className='border-lp-gray-002' />

      <EventMessageSettingsSection
        celebration={celebration}
        messageSettings={celebration.messageSettings}
        onChange={handleUpdate}
        type={CelebrationEventType.Birthday}
      />

      <hr className='border-lp-gray-002' />

      <EventMessageSettingsSection
        celebration={celebration}
        messageSettings={celebration.messageSettings}
        onChange={handleUpdate}
        type={CelebrationEventType.WorkAnniversary}
      />
    </div>
  );
}
