import { Route, Routes } from '@remix-run/react';
import { Fragment } from 'react';

import { type Organization } from '../../types';
import { LayoutAnchorProvider } from '../LayoutAnchors/LayoutAnchors';
import { OrgFeatureProvider } from '../Organization';
import { ProgramDispatcher } from './ProgramDispatcher';
import { ProgramLinkNew } from './ProgramLinkNew';
import { ProgramList } from './ProgramList';
import { ProgramMgmtLayout } from './ProgramMgmtLayout';

export * from '../../types/program';
export * from './Navbar';
export { ProgramCard } from './ProgramCard';
export { ProgramDetailCard } from './ProgramDetailCard';
export { ProgramDetailLayout, ProgramDetailTabs } from './ProgramDetailLayout';
export type { ProgramDetailTabItem } from './ProgramDetailLayout';
export { ProgramDispatcher } from './ProgramDispatcher';
export { useProgramPicker } from './ProgramPicker';
export * from './ProgramStatusControl';
export { AdminProgramList } from './AdminProgramList';
export { ProgramEditor } from './ProgramEditor';
export { ProgramMemberList } from './ProgramMemberList';
export { ProgramActivation } from './ProgramActivation';
export type { BasicProgramFormData } from './types';
export { ProgramEditorLayout } from './ProgramEditorLayout';
export * from './ProgramKnifes';

// eslint-disable-next-line import/no-default-export
export default function Home(props: {
  org: Organization;
  routePrefix: string;
  withLayout?: boolean;
  adminMode?: boolean;
}): JSX.Element {
  const Layout = props.withLayout ? ProgramMgmtLayout : Fragment;
  return (
    <OrgFeatureProvider {...props}>
      <Routes>
        <Route index element={<ProgramList />} />
        <Route path='new' element={<ProgramLinkNew />} />
        <Route
          path=':linkId/*'
          element={
            <Layout>
              <LayoutAnchorProvider>
                <ProgramDispatcher />
              </LayoutAnchorProvider>
            </Layout>
          }
        />
      </Routes>
    </OrgFeatureProvider>
  );
}
