import { useCallback } from 'react';

import SlackChannelMemberMgmt1x from '../../../assets/img/slack-channel-member-mgmt@1x.png';
import SlackChannelMemberMgmt3x from '../../../assets/img/slack-channel-member-mgmt@3x.png';
import { buildSrcSet } from '../../../utils/media';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';

function SlackManageUserModal(props: {
  channelName: string;
  onComplete: () => void;
}): JSX.Element {
  return (
    <ModalWrapper containerClassName='w-172' borderStyle='gray'>
      <div className='w-full h-full min-h-52 flex flex-col items-center justify-center px-12 py-10'>
        <header className='font-medium text-2xl flex flex-col items-center'>
          <img
            srcSet={buildSrcSet(
              [SlackChannelMemberMgmt1x, SlackChannelMemberMgmt3x],
              true
            )}
            alt='Manage Slack channel members'
          />
        </header>
        <section className='w-full items-center text-center text-sms my-5'>
          <div className='mt-4 text-2xl font-medium'>
            Want to Add or Remove a Participant?
          </div>
          <div className='mt-4 mb-2'>
            All user management is done through Slack. Simply add or remove the
            participant from{' '}
            <span className='whitespace-nowrap'>#{props.channelName}</span> on
            Slack!
          </div>
        </section>
        <footer className='w-full flex items-center justify-center gap-4'>
          <button
            type='button'
            className='btn-primary w-40 h-10 flex items-center justify-center'
            onClick={() => props.onComplete()}
          >
            Got it
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

export function useTriggerSlackManageUserModal(): (
  channelName: string
) => void {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  return useCallback(
    (channelName: string) => {
      triggerFullScreenModal({
        kind: 'custom',
        containerClassName: 'bg-black bg-opacity-60',
        element: (p) => (
          <SlackManageUserModal
            channelName={channelName}
            onComplete={p.internalOnConfirm}
          />
        ),
      });
    },
    [triggerFullScreenModal]
  );
}
