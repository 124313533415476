import { useMemo } from 'react';
import useSWR, { type KeyedMutator } from 'swr';

import { apiService } from '../../../services/api-service';
import { type Celebration } from '../../../types/celebration';

interface useCelebrationReturned {
  celebration?: Celebration;
  isLoading: boolean;
  error?: Error | null;
  mutate: KeyedMutator<Celebration>;
}

export function useCelebration(props: { id: string }): useCelebrationReturned {
  const { data, error, isValidating, mutate } = useSWR(
    props.id ? `/celebrations/${props.id}` : null,
    async () =>
      (await apiService.celebration.getCelebration(props.id)).data.celebration
  );

  return useMemo(
    () => ({
      celebration: data,
      isLoading: !data && isValidating,
      error,
      mutate,
    }),
    [data, isValidating, error, mutate]
  );
}
