import { useParams } from '@remix-run/react';
import useSWRImmutable from 'swr/immutable';

import { EnumsProgramType } from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { assertExhaustive, err2s } from '../../utils/common';
import { CelebrationProgram } from '../Celebrations';
import { GlobalLoading } from '../GlobalLoading';
import { GlobalPairingProgram } from '../GlobalPairingTournament';
import { CalendarProgram } from './Calendar';
import { IntrosProgram } from './Intros';
import { RecognitionProgram } from './Recognition';
import { WaterCoolerProgram } from './WaterCooler';

export function ProgramDispatcher(): JSX.Element | null {
  const { linkId = '' } = useParams<'linkId'>();

  const {
    data: channel,
    error,
    isLoading,
  } = useSWRImmutable(`/channels/program-links/${linkId}`, async () => {
    const resp = await apiService.channel.getChannelByProgramLinkId(linkId);
    return resp.data.channel;
  });

  if (isLoading) return <GlobalLoading />;
  if (error) return <div className='text-sms text-red-002'>{err2s(error)}</div>;
  const link = channel?.programLinks.find((link) => link.id === linkId);
  if (!channel || !link) {
    return <div className='text-sms text-red-002'>Program Not Found</div>;
  }

  switch (link.programType) {
    case EnumsProgramType.ProgramTypeGlobalPairing:
      return <GlobalPairingProgram channel={channel} programLink={link} />;
    case EnumsProgramType.ProgramTypeGameDrops:
      return <GlobalPairingProgram channel={channel} programLink={link} />;
    case EnumsProgramType.ProgramTypeBirthdayAndCelebrations:
      return <CelebrationProgram channel={channel} programLink={link} />;
    case EnumsProgramType.ProgramTypeCalendar:
      return <CalendarProgram channel={channel} programLink={link} />;
    case EnumsProgramType.ProgramTypeIntros:
      return <IntrosProgram channel={channel} programLink={link} />;
    case EnumsProgramType.ProgramTypeWaterCooler:
      return <WaterCoolerProgram channel={channel} programLink={link} />;
    case EnumsProgramType.ProgramTypeRecognition:
      return <RecognitionProgram channel={channel} programLink={link} />;
    case EnumsProgramType.ProgramTypeDummy:
    case EnumsProgramType.ProgramTypeAiSurvey:
    case EnumsProgramType.ProgramTypeCustomTournament:
      return null;
    default:
      assertExhaustive(link.programType);
      return null;
  }
}
