import { Route, Routes } from '@remix-run/react';

import {
  type DtoChannel,
  type DtoChannelProgramLink,
} from '@lp-lib/api-service-client/public';

import { ProgramMemberList } from '../../Program';
import { TournamentConfiguration } from './Configuration';
import { TournamentRounds } from './Rounds';

export function TournamentDetailRoutes(props: {
  channel: DtoChannel;
  programLink: DtoChannelProgramLink;
}): JSX.Element {
  const { channel, programLink } = props;

  return (
    <Routes>
      <Route
        index
        element={
          <ProgramMemberList
            channel={channel}
            programLink={programLink}
            managable={programLink.programType === 'global_pairing'}
          />
        }
      />
      <Route path='rounds' element={<TournamentRounds channel={channel} />} />
      <Route
        path='admin'
        element={
          <TournamentConfiguration
            channel={channel}
            programLink={programLink}
          />
        }
      />
    </Routes>
  );
}
