import { useOrgFeatureContext } from '../../Organization';
import { ProgramActivateButton, ProgramDeactivateButton } from '../../Program';
import { useBasicProgramFromLink } from '../../Program/usePrograms';
import { type CelebrationTabProps } from '../types';
import { useCelebrationTitle } from '../utils';

export function CelebrationTabAdmin(
  props: CelebrationTabProps
): JSX.Element | null {
  const { channel, programLink } = props;
  const { data: program } = useBasicProgramFromLink(programLink);

  useCelebrationTitle({
    tab: 'Admin',
    channel,
  });

  const { routePrefix } = useOrgFeatureContext();

  if (!program) return null;

  return (
    <div className='flex flex-col text-white min-h-100'>
      <div className='max-w-100'>
        {programLink.status === 'active' ? (
          <ProgramDeactivateButton
            channel={channel}
            programLink={programLink}
            program={program}
            routePrefix={routePrefix}
          />
        ) : (
          <ProgramActivateButton
            channel={channel}
            programLink={programLink}
            program={program}
            routePrefix={routePrefix}
          />
        )}
      </div>
    </div>
  );
}
