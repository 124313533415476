import {
  castProgramRounds,
  type WaterCoolerProgramRound,
} from '../../../types/program';
import { useProgramRounds } from '../usePrograms';

export function useWaterCoolerPastRounds(linkId: string) {
  const swr = useProgramRounds(linkId);
  const rounds = swr.data ?? [];

  const filteredRounds = castProgramRounds<WaterCoolerProgramRound>(
    rounds
      .filter((r) => r.status === 'completed')
      .sort((a, b) => {
        const aTs = a.startedAt ? new Date(a.startedAt) : new Date();
        const bTs = b.startedAt ? new Date(b.startedAt) : new Date();
        return bTs.getTime() - aTs.getTime();
      })
  );

  return { ...swr, data: filteredRounds };
}
