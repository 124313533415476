import { useNavigate } from '@remix-run/react';
import { useCallback } from 'react';

import {
  type DtoBasicProgram,
  type DtoChannel,
  type DtoChannelProgramLink,
} from '@lp-lib/api-service-client/public';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { safeWindowReload } from '../../logger/logger';
import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { Loading } from '../Loading';

export function ProgramDeactivateButton(props: {
  channel: DtoChannel;
  programLink: DtoChannelProgramLink;
  program: DtoBasicProgram;
  routePrefix: string;
}): JSX.Element {
  const { channel, programLink, program, routePrefix } = props;
  const navigate = useNavigate();
  const confirm = useAwaitFullScreenConfirmCancelModal();
  const {
    state: { transformed: state },
    error,
    call: deactivate,
  } = useAsyncCall(
    useCallback(async (channelId: string, linkId: string) => {
      return apiService.channel.deactivateProgramLink(channelId, linkId);
    }, [])
  );

  const handleDeactivate = async () => {
    const confirmed = await confirm({
      kind: 'confirm-cancel',
      prompt: (
        <div className='text-2xl font-medium text-white text-center'>
          Are you sure you want to deactivate {program.name}
        </div>
      ),
      confirmBtnLabel: 'Confirm',
      confirmBtnVariant: 'delete',
      cancelBtnLabel: 'Cancel',
      containerClassName: 'bg-black bg-opacity-60',
      boxDimensionsClassName: 'w-86 h-60 px-4 py-2',
    });
    if (confirmed.result === 'canceled') return;
    const resp = await deactivate(channel.id, programLink.id);
    if (resp === undefined) return;
    navigate(`${routePrefix}/channels`, { replace: true });
    safeWindowReload();
  };

  return (
    <div>
      <button
        type='button'
        className='flex items-center justify-center px-4 h-10 btn-delete'
        onClick={handleDeactivate}
        disabled={state.isRunning}
      >
        {state.isRunning && <Loading text='' containerClassName='mr-2' />}
        Deactivate {program.name}
      </button>
      {error && (
        <div className='text-red-002 text-sms mt-1'>{err2s(error)}</div>
      )}
    </div>
  );
}

export function ProgramActivateButton(props: {
  channel: DtoChannel;
  programLink: DtoChannelProgramLink;
  program: DtoBasicProgram;
  routePrefix: string;
}): JSX.Element {
  const { channel, programLink, program, routePrefix } = props;
  const navigate = useNavigate();
  const {
    state: { transformed: state },
    error,
    call: activate,
  } = useAsyncCall(
    useCallback(async (channelId: string, linkId: string) => {
      return apiService.channel.activateProgramLink(channelId, linkId);
    }, [])
  );

  const handleActivate = async () => {
    const resp = await activate(channel.id, programLink.id);
    if (resp === undefined) return;
    navigate(`${routePrefix}/programs/${programLink.id}`, {
      replace: true,
    });
    safeWindowReload();
  };

  return (
    <div>
      <button
        type='button'
        className='flex items-center justify-center px-4 h-10 btn-primary'
        onClick={handleActivate}
        disabled={state.isRunning}
      >
        {state.isRunning && <Loading text='' containerClassName='mr-2' />}
        Activate {program.name}
      </button>
      {error && (
        <div className='text-red-002 text-sms mt-1'>{err2s(error)}</div>
      )}
    </div>
  );
}
