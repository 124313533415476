import { useTitle } from 'react-use';

import { useInstance } from '../../../hooks/useInstance';
import {
  castProgramRounds,
  type IntrosProgramRound,
} from '../../../types/program';
import { err2s, makeTitle } from '../../../utils/common';
import { Loading } from '../../Loading';
import { type ProgramDetailProps } from '../types';
import { IntrosProgramRoundList } from './IntrosProgramRound';
import { useIntrosPastRounds } from './utils';

function Container(props: ProgramDetailProps) {
  const { programLink } = props;
  const { data, isLoading, error } = useIntrosPastRounds(programLink.id);
  const timezone = useInstance(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  if (isLoading) return <Loading text='' />;
  if (error) return <div className='text-sms text-red-002'>{err2s(error)}</div>;
  const rounds = castProgramRounds<IntrosProgramRound>(data ?? []);

  return (
    <IntrosProgramRoundList {...props} rounds={rounds} timezone={timezone} />
  );
}

export function IntrosPastRounds(props: ProgramDetailProps) {
  useTitle(makeTitle('Past Rounds'));
  return (
    <div className='w-full flex items-center justify-center text-white mt-8'>
      <Container {...props} />
    </div>
  );
}
