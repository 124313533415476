import capitalize from 'lodash/capitalize';
import { useTitle } from 'react-use';
import useSWR, { mutate } from 'swr';

import {
  type DtoChannel,
  type DtoChannelMember,
  type DtoChannelProgramLink,
  EnumsExConnectType,
  EnumsMemberStatus,
} from '@lp-lib/api-service-client/public';
import { assertExhaustive } from '@lp-lib/game';

import { apiService } from '../../services/api-service';
import { err2s, makeTitle } from '../../utils/common';
import { type Action, type ActionButton, ActionSheet } from '../ActionSheet';
import {
  ChannelMemberAvatar,
  ChannelUtils,
  useChannelPerm,
  useInviteUsers,
  useTriggerAddMembersFromOrgModal,
  useTriggerSlackManageUserModal,
} from '../Channel';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ChatSendIcon } from '../icons/Chat/ChatSendIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { PauseIcon } from '../icons/PauseIcon';
import { PlayIcon } from '../icons/PlayIcon';
import { Loading } from '../Loading';
import { useOrgFeatureContext } from '../Organization';
import { type MemberActionSheetKeys } from './types';

function MemberActionSheet(props: {
  channel: DtoChannel;
  member: DtoChannelMember;
  onClick: (key: MemberActionSheetKeys) => void;
}): JSX.Element {
  const { channel, member, onClick } = props;
  const perm = useChannelPerm(channel);

  function buildAction(
    action: Omit<ActionButton<MemberActionSheetKeys>, 'onClick'>
  ): ActionButton<MemberActionSheetKeys> {
    return {
      ...action,
      onClick: () => {
        onClick(action.key);
      },
    };
  }
  const actions: Action<MemberActionSheetKeys>[] = [];

  if (perm.isAdmin && member.status !== 'deactivated') {
    actions.push(
      buildAction({
        kind: 'button',
        key: 'snooze',
        icon:
          member.status === 'active' ? (
            <PauseIcon className='w-3.5 h-3.5' />
          ) : (
            <PlayIcon />
          ),
        text: member.status === 'active' ? 'Snooze' : 'Unsnooze',
      })
    );
  }

  if (perm.isAdmin || perm.isChannelCreator) {
    if (member.status === 'deactivated' && member.exUserId) {
      actions.push(
        buildAction({
          kind: 'button',
          key: 'invite',
          icon: <ChatSendIcon />,
          text: 'Invite to Luna Park',
        })
      );
    }

    actions.push(
      buildAction({
        kind: 'button',
        key: 'remove',
        icon: <DeleteIcon />,
        className: 'text-red-002',
        text: 'Remove',
      })
    );
  }

  return <ActionSheet actions={actions} />;
}

function MemberItem(props: {
  channel: DtoChannel;
  member: DtoChannelMember;
  exInfoEnabled: boolean;
  triggerRefresh: () => void;
  managable: boolean;
}): JSX.Element {
  const { channel, member, exInfoEnabled, triggerRefresh, managable } = props;
  const { org } = useOrgFeatureContext();
  const perm = useChannelPerm(channel);
  const triggerSlackManageUserModal = useTriggerSlackManageUserModal();
  const inviteUsers = useInviteUsers();
  const confirm = useAwaitFullScreenConfirmCancelModal();

  const handleRemove = async () => {
    switch (channel.exConnectType) {
      case EnumsExConnectType.ExConnectTypeSlack:
        triggerSlackManageUserModal(channel.name);
        break;
      case EnumsExConnectType.ExConnectTypeTeams:
      case EnumsExConnectType.ExConnectTypeLpOrgAdmin:
      case EnumsExConnectType.ExConnectTypeLpOrgAllMembers:
        // dont't support, managable should be false
        break;
      case undefined:
      case null:
        const resp = await confirm({
          kind: 'confirm-cancel',
          prompt: (
            <div className='text-2xl font-medium text-white text-center'>
              Are you sure you want to remove this Participant from the Channel?
            </div>
          ),
          confirmBtnLabel: 'Remove',
          confirmBtnVariant: 'delete',
          cancelBtnLabel: 'Cancel',
          containerClassName: 'bg-black bg-opacity-60',
          boxDimensionsClassName: 'w-86 h-60 px-4 py-2',
        });
        if (resp.result === 'canceled') return;
        await apiService.channel.removeMember(channel.id, member.id);
        triggerRefresh();
        break;
      default:
        assertExhaustive(channel.exConnectType);
        break;
    }
  };

  const handleSnooze = async () => {
    switch (member.status) {
      case EnumsMemberStatus.MemberStatusActive:
        await apiService.channel.snoozeMember(member.channelId, member.id);
        triggerRefresh();
        break;
      case EnumsMemberStatus.MemberStatusSnoozed:
        await apiService.channel.unSnoozeMember(member.channelId, member.id);
        triggerRefresh();
        break;
      case EnumsMemberStatus.MemberStatusDeactivated:
        break;
      default:
        assertExhaustive(member.status);
        break;
    }
  };

  const handleInvite = async () => {
    if (
      member.status !== 'deactivated' ||
      !member.exUserId ||
      !channel.exGroupId
    )
      return;
    await inviteUsers(channel.orgId, channel.id, [
      {
        id: member.exUserId,
        teamId: channel.exGroupId,
        email: member.email,
        fullName: member.fullName,
        displayName: member.displayName,
        icon: member.icon || undefined,
      },
    ]);
    triggerRefresh();
  };

  const handleActionClick = async (key: MemberActionSheetKeys) => {
    switch (key) {
      case 'invite':
        await handleInvite();
        break;
      case 'remove':
        await handleRemove();
        break;
      case 'snooze':
        await handleSnooze();
        break;
      default:
        assertExhaustive(key);
        break;
    }
  };

  return (
    <tr className='text-sms h-12'>
      <td>
        <div className='flex items-center'>
          <ChannelMemberAvatar
            exConnectType={channel.exConnectType}
            member={member}
            className='w-9 h-9 mr-2'
          />
          <div
            className={`${
              member.status === 'snoozed' ? 'text-icon-gray' : 'text-white'
            }`}
          >
            {member.fullName}
          </div>
        </div>
      </td>
      <td>
        <div
          className={`${
            member.status === 'snoozed'
              ? 'text-icon-gray'
              : member.status === 'deactivated'
              ? 'text-red-002'
              : 'text-white'
          }`}
        >
          {capitalize(member.status)}
          {!!member.orgId && org.id !== member.orgId && `(External)`}
        </div>
      </td>
      {exInfoEnabled && <td>{member.displayName}</td>}
      {exInfoEnabled && <td>{member.email}</td>}
      <td className='flex items-center justify-end'>
        {managable && (perm.isAdmin || perm.isChannelCreator) && (
          <MemberActionSheet
            channel={channel}
            member={member}
            onClick={handleActionClick}
          />
        )}
      </td>
    </tr>
  );
}

export function MemberList(props: {
  channel: DtoChannel;
  managable: boolean;
}): JSX.Element {
  const { channel, managable } = props;
  const { org, adminMode } = useOrgFeatureContext();
  const triggerSlackManageUserModal = useTriggerSlackManageUserModal();
  const triggerAddMembersFromOrgModal = useTriggerAddMembersFromOrgModal();

  const {
    data: members,
    error,
    isLoading,
    mutate: mutateMembers,
  } = useSWR(`/channels/${channel.id}/members`, async () => {
    const resp = await apiService.channel.getMembers(channel.id);
    return resp.data.members;
  });

  if (isLoading) return <Loading />;
  if (error || members === undefined)
    return <div className='text-red-002'>{err2s(error ?? 'Something is')}</div>;

  const exInfoEnabled = !!adminMode && !!channel.exConnectType;

  const refresh = () => {
    mutateMembers();
    mutate(`/channels/${channel.id}`);
  };

  const handleAddMember = async () => {
    switch (channel.exConnectType) {
      case EnumsExConnectType.ExConnectTypeSlack:
        triggerSlackManageUserModal(channel.name);
        break;
      case EnumsExConnectType.ExConnectTypeTeams:
      case EnumsExConnectType.ExConnectTypeLpOrgAdmin:
      case EnumsExConnectType.ExConnectTypeLpOrgAllMembers:
        break;
      case undefined:
      case null:
        triggerAddMembersFromOrgModal({
          org,
          channel: channel,
          excludedUids: members
            .map((p) => p.uid)
            .filter((u): u is string => !!u),
          onComplete: refresh,
        });
        break;
      default:
        assertExhaustive(channel.exConnectType);
        break;
    }
  };

  return (
    <table className='w-full'>
      <thead>
        <tr className='text-left h-10'>
          <th className='w-1/3'>Name</th>
          <th className='w-1/5'>Status</th>
          {exInfoEnabled && <th>Display Name</th>}
          {exInfoEnabled && <th>Email Address</th>}
          <th className='flex items-center justify-end'>
            {channel.status === 'active' && managable && (
              <button
                type='button'
                className='text-primary font-medium outline-none focus:outline-none'
                onClick={handleAddMember}
              >
                +Add Participant
              </button>
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {members.map((p) => (
          <MemberItem
            key={p.id}
            channel={channel}
            member={p}
            exInfoEnabled={exInfoEnabled}
            triggerRefresh={refresh}
            managable={managable}
          />
        ))}
        {members.length === 0 && (
          <div className='text-secondary text-sms '>
            No members in this channel
          </div>
        )}
      </tbody>
    </table>
  );
}

function SyncHint(props: { channel: DtoChannel }): JSX.Element | null {
  const { channel } = props;

  if (channel.status === 'inactive')
    return <div className='text-[#8B8B8B] text-xs'>Detached</div>;

  let hint = '';
  switch (channel.exConnectType) {
    case EnumsExConnectType.ExConnectTypeSlack:
      hint = `This participant list is synchronized with your slack channel, ${ChannelUtils.ChannelName(
        channel
      )}.`;
      break;
    case EnumsExConnectType.ExConnectTypeTeams:
      hint = `This participant list is synchronized with your Teams channel, ${ChannelUtils.ChannelName(
        channel
      )}.`;
      break;
    case EnumsExConnectType.ExConnectTypeLpOrgAdmin:
    case EnumsExConnectType.ExConnectTypeLpOrgAllMembers:
      hint = 'This participant list is synchronized with your organization.';
      break;
    case undefined:
    case null:
      hint = 'Connect to a Slack Channel to sync this list of Participants.';
      break;
    default:
      assertExhaustive(channel.exConnectType);
      break;
  }

  return <div className='text-[#8B8B8B] text-xs'>{hint}</div>;
}

export function ProgramMemberList(props: {
  channel: DtoChannel;
  programLink: DtoChannelProgramLink;
  managable?: boolean;
}): JSX.Element {
  const { channel } = props;
  const { org } = useOrgFeatureContext();
  useTitle(
    makeTitle(`Participants | ${ChannelUtils.ChannelName(channel, org)}`)
  );
  return (
    <div className='w-full flex flex-col'>
      <SyncHint channel={channel} />
      <div className='w-full flex items-center justify-center text-white mt-6'>
        <MemberList channel={channel} managable={!!props.managable} />
      </div>
    </div>
  );
}
