import { type DtoChannel } from '@lp-lib/api-service-client/public';

import { OrganizerRoleUtils, RoleUtils } from '../../../types';
import { useUser } from '../../UserContext';

export function useChannelPerm(channel: DtoChannel) {
  const user = useUser();
  const isAdmin = RoleUtils.isAdmin(user);
  const isChannelCreator = user.id === channel.uid;
  const isOrgOwnerOrAdmin = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer);
  return {
    isAdmin,
    isChannelCreator,
    isOrgOwnerOrAdmin,
    managable: isAdmin || isChannelCreator || isOrgOwnerOrAdmin,
  };
}
