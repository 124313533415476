import { Navigate, Route, Routes } from '@remix-run/react';
import { useMemo } from 'react';

import {
  type DtoBasicProgram,
  type DtoChannel,
  type DtoChannelProgramLink,
} from '@lp-lib/api-service-client/public';

import { useChannelPerm } from '../../Channel';
import { ProgramActivation } from '../ProgramActivation';
import {
  ProgramDetailLayout,
  type ProgramDetailTabItem,
  ProgramDetailTabs,
} from '../ProgramDetailLayout';
import { ProgramMemberList } from '../ProgramMemberList';
import { type ProgramDetailProps } from '../types';
import { RecognitionProgramDetails } from './RecognitionProgramDetails';
import { RecognitionProgramUserSettings } from './RecognitionProgramUserSettings';

function useTabItems(
  channel: DtoChannel,
  _programLink: DtoChannelProgramLink,
  _program: DtoBasicProgram
) {
  const perm = useChannelPerm(channel);

  return useMemo(() => {
    const tabItems: ProgramDetailTabItem[] = [
      {
        title: 'Setup',
        url: './settings',
      },
      {
        title: 'Leaderboard',
        url: './leaderboard',
      },
      {
        title: `Participants(${channel.membersCount})`,
        url: './participants',
      },
    ];
    if (perm.managable) {
      tabItems.push({
        title: 'Admin',
        url: `./admin`,
      });
    }
    return tabItems;
  }, [channel.membersCount, perm.managable]);
}

function ProgramRoutes(props: ProgramDetailProps): JSX.Element {
  return (
    <Routes>
      <Route index element={<Navigate to='./settings' replace />} />
      <Route
        path='settings'
        element={<RecognitionProgramUserSettings {...props} />}
      />
      <Route
        path='leaderboard'
        element={<RecognitionProgramDetails {...props} />}
      />
      <Route
        path='participants'
        element={<ProgramMemberList {...props} managable={false} />}
      />
      <Route path='admin' element={<ProgramActivation {...props} />} />
    </Routes>
  );
}

export function RecognitionProgram(props: {
  channel: DtoChannel;
  programLink: DtoChannelProgramLink;
}): JSX.Element {
  const { channel, programLink } = props;
  const basicProgram = programLink.basicProgram;
  if (!basicProgram) throw new Error('Program info is missing.');

  const items = useTabItems(channel, programLink, basicProgram);

  return (
    <ProgramDetailLayout {...props} program={basicProgram}>
      <ProgramDetailTabs channelId={channel.id} items={items} />
      <ProgramRoutes
        channel={channel}
        programLink={programLink}
        program={basicProgram}
      />
    </ProgramDetailLayout>
  );
}
