import { format } from 'date-fns';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';

function SetDateModal(props: {
  title: string;
  hint?: React.ReactNode;
  initDate: Date | null;
  dateFormat?: string;
  onCancel: () => void;
  onSave: (value: Date | null) => void;
}) {
  const { title, hint, initDate, dateFormat, onCancel, onSave } = props;

  const [selectedDate, setSelectedDate] = useState(initDate);

  return (
    <ModalWrapper
      containerClassName='w-132'
      borderStyle='gray'
      onClose={onCancel}
    >
      <div className='w-full px-12.5 py-10 flex flex-col items-center text-center'>
        <header>{title}</header>
        <main className='mt-6 w-full'>
          {hint && <p className='text-sms mb-5'>{hint}</p>}
          <ReactDatePicker
            className={`field h-15 mb-0`}
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            placeholderText='select a date'
            dateFormat={dateFormat}
            isClearable
            showMonthDropdown
            useShortMonthInDropdown
            showYearDropdown
            dropdownMode='select'
          />
        </main>
        <footer className='mt-10 flex flex-row items-center gap-5'>
          <button className='btn-secondary w-40 h-10' onClick={onCancel}>
            Cancel
          </button>
          <button
            className='btn-primary w-40 h-10'
            onClick={() => onSave(selectedDate)}
          >
            Save
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

export function CelebrationDateEditor(props: {
  value: Date | null;
  displayFormat: string;
  optedOut: boolean;
  editable: boolean;
  modalTitle: string;
  modalHint?: React.ReactNode;
  onChange: (updated: Date | null) => void;
}): JSX.Element {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleEdit = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <SetDateModal
          title={props.modalTitle}
          hint={props.modalHint}
          initDate={props.value}
          dateFormat={props.displayFormat}
          onCancel={p.internalOnCancel}
          onSave={(date) => {
            props.onChange(date);
            p.internalOnConfirm();
          }}
        />
      ),
    });
  };

  if (props.optedOut) {
    return <p className='text-icon-gray text-sms'>Opted out</p>;
  }

  if (!props.value) {
    return props.editable ? (
      <button className='btn-secondary w-24 h-8' onClick={handleEdit}>
        Add date
      </button>
    ) : (
      <p className='text-icon-gray text-sms'>N/A</p>
    );
  }

  return (
    <div className='flex items-center gap-2 text-sms'>
      <p>{format(props.value, props.displayFormat)}</p>
      {props.editable && (
        <button className='btn text-primary' onClick={handleEdit}>
          Edit
        </button>
      )}
    </div>
  );
}
