import { useNavigate } from '@remix-run/react';
import { addDays, format } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTitle } from 'react-use';
import { useSWRConfig } from 'swr';

import {
  type DtoChannelProgramLink,
  EnumsMessageCampaignType,
  EnumsMessageTriggerEventName,
  EnumsMessageTriggerType,
  EnumsPairingParticipationMode,
  EnumsProgramCadenceFrequency,
  type ModelsMessageTemplateV2,
} from '@lp-lib/api-service-client/public';

import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useBlockLeave } from '../../../hooks/useUnload';
import { apiService } from '../../../services/api-service';
import { makeTitle } from '../../../utils/common';
import { useChannelPerm } from '../../Channel';
import {
  MessageTemplatePreview,
  useMessageLogics,
  useTriggerMessageTemplateEditorModal,
} from '../../Message';
import { useOrgFeatureContext } from '../../Organization';
import {
  castProgramLink,
  castProgramRounds,
  type IntrosProgramLink,
  type IntrosProgramRound,
} from '..';
import {
  ProgramCadenceEditor,
  type ProgramCadenceFormData,
} from '../ProgramCadenceEditor';
import { SubmitActions, useUpdateTabRightNode } from '../ProgramDetailLayout';
import {
  ProgramGroupEditor,
  type ProgramGroupFormData,
} from '../ProgramGroupEditor';
import { type ProgramDetailProps } from '../types';
import { useProgramRounds } from '../usePrograms';
import { ProgramUtils, resetFormAndGoBack } from '../utils';
import { IntrosProgramUtils } from './utils';

type FormData = ProgramCadenceFormData &
  ProgramGroupFormData & {
    extensions: {
      introMessageTemplate: ModelsMessageTemplateV2;
    };
  };

function IntroMessageEditor(props: {
  programLink: DtoChannelProgramLink;
  disabled?: boolean;
}) {
  const triggerModal = useTriggerMessageTemplateEditorModal();
  const { watch, setValue } = useFormContext<FormData>();
  const frequency = watch('cadenceSettings.frequency');
  const customizedTemplate = watch('extensions.introMessageTemplate');
  const nextTriggerTime = watch('cadenceSettings.nextTriggerTime');

  const { data: programRounds } = useProgramRounds(
    props.programLink.programId || ''
  );
  const programRound = useMemo(
    () =>
      castProgramRounds<IntrosProgramRound>(programRounds)?.find(
        (r) => r.extensions?.frequency === frequency
      ),
    [frequency, programRounds]
  );
  const { data: logics } = useMessageLogics(
    EnumsMessageCampaignType.MessageCampaignTypeProgramRound,
    programRound?.id || ''
  );
  const introMessageLogic = useMemo(
    () =>
      logics?.find(
        (logic) =>
          logic.trigger.type ===
            EnumsMessageTriggerType.MessageTriggerTypeEventDriven &&
          logic.trigger.eventName ===
            EnumsMessageTriggerEventName.MessageTriggerEventNameGroupsAssigned
      ),
    [logics]
  );

  const template = useMemo(() => {
    if (customizedTemplate.text) return customizedTemplate;
    if (introMessageLogic?.template) return introMessageLogic.template;
    return {
      buttons: [],
      media: null,
      text: '',
    };
  }, [customizedTemplate, introMessageLogic]);
  const vars = useMemo(() => {
    const endDate = addDays(nextTriggerTime.date, frequency * 7);

    return {
      recipientName: '<@participants1>, <@participants2>',
      roundStartDate: format(nextTriggerTime.date, 'EEE, MMM do, yyyy'),
      roundEndDate: format(endDate, 'EEE, MMM do, yyyy'),
      groupGameLink: window.origin,
      groupCoordinatorName: '<@participants1>',
    };
  }, [frequency, nextTriggerTime.date]);

  const openMessageTemplateEditor = () => {
    triggerModal({
      template,
      vars,
      mediaVars: {},
      mediaEditable: true,
      saveButtonText: 'Save Custom Message',
      onSave: (template) => {
        setValue('extensions.introMessageTemplate', template);
      },
    });
  };

  return (
    <section className='w-full flex flex-col gap-5'>
      <header className='flex items-center gap-2 text-2xl font-medium'>
        Messages
      </header>
      <main className='flex gap-10'>
        <div className='flex flex-col gap-2 w-90'>
          <div className='font-bold'>Intro Message</div>
          <div className='text-sms text-icon-gray'>
            The message that groups receive when Luna Park introduces them via
            DM in Slack
          </div>
          <button
            type='button'
            className='btn-secondary w-55 h-10 mt-8'
            onClick={openMessageTemplateEditor}
            disabled={props.disabled}
          >
            Customize Message
          </button>
        </div>
        <div className='w-130 bg-modal'>
          <MessageTemplatePreview
            template={template}
            vars={vars}
            mediaVars={{}}
            className='min-h-72'
          />
        </div>
      </main>
    </section>
  );
}

function makeDefaultValues(
  programLink: IntrosProgramLink,
  roundTimeToMinutes: number
): FormData {
  const { cadenceSettings, groupSettings } = programLink;
  const frequency =
    cadenceSettings?.frequency ??
    EnumsProgramCadenceFrequency.ProgramCadenceFrequencyEveryWeek;
  const weekdays =
    !!cadenceSettings?.weekdays && cadenceSettings.weekdays.length > 0
      ? cadenceSettings.weekdays
      : [1, 2];
  const participationMode =
    groupSettings?.participationMode ??
    EnumsPairingParticipationMode.PairingParticipationModeNone;
  return {
    cadenceSettings: {
      frequency,
      weekdays,
      nextTriggerTime: {
        date: cadenceSettings?.nextTriggerTime
          ? new Date(cadenceSettings.nextTriggerTime)
          : ProgramUtils.NextCadenceAfter(
              frequency,
              weekdays,
              undefined,
              roundTimeToMinutes
            ),
        timezone:
          cadenceSettings?.timezone ??
          Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
    groupSettings: {
      participationMode,
      size: groupSettings?.size ?? 2,
    },
    extensions: {
      introMessageTemplate: programLink.extensions?.introMessageTemplate ?? {
        buttons: [],
        media: null,
        text: '',
      },
    },
  };
}

export function IntrosProgramDetails(props: ProgramDetailProps): JSX.Element {
  const programLink = castProgramLink<IntrosProgramLink>(props.programLink);
  const perm = useChannelPerm(props.channel);
  useTitle(makeTitle(`${props.program.name || 'Details'}`));

  const cadenceConfig = useInstance(() =>
    IntrosProgramUtils.GetCadenceConfig()
  );

  const form = useForm<FormData>({
    defaultValues: makeDefaultValues(
      programLink,
      cadenceConfig.roundTimeToMinutes
    ),
    mode: 'onChange',
  });
  const { routePrefix } = useOrgFeatureContext();
  const navigate = useNavigate();

  const { isSubmitting, isValid, isDirty } = form.formState;

  const { mutate } = useSWRConfig();
  const mutateAndGoBack = () => {
    mutate(`/channels/program-links/${programLink.id}`);
    navigate(`${routePrefix}/channels`);
  };

  const onSave = useLiveCallback(async () => {
    await form.handleSubmit(async (data) => {
      await apiService.channel.updateProgramLink(
        programLink.channelId,
        programLink.id,
        {
          cadenceSettings: {
            frequency: data.cadenceSettings.frequency,
            nextTriggerTime:
              data.cadenceSettings.nextTriggerTime.date.toISOString(),
            timezone: data.cadenceSettings.nextTriggerTime.timezone,
            weekdays:
              data.cadenceSettings.frequency === 0
                ? data.cadenceSettings.weekdays
                : [],
          },
          groupSettings: data.groupSettings,
          extensions: data.extensions,
        }
      );
    })();
    resetFormAndGoBack(form, mutateAndGoBack);
  });

  const onCancel = useLiveCallback(() =>
    resetFormAndGoBack(form, mutateAndGoBack)
  );

  const updateTabRightNode = useUpdateTabRightNode();
  useEffect(() => {
    if (!perm.managable) return;

    updateTabRightNode(
      <SubmitActions
        isSubmitting={isSubmitting}
        isValid={isValid}
        onSave={onSave}
        onCancel={onCancel}
      />
    );
    return () => updateTabRightNode(null);
  }, [
    isSubmitting,
    isValid,
    onCancel,
    onSave,
    perm.managable,
    updateTabRightNode,
  ]);

  useBlockLeave(() => !isDirty);

  return (
    <FormProvider {...form}>
      <div
        className='w-full flex flex-col items-center justify-center gap-15 
    text-white mt-8'
      >
        <ProgramCadenceEditor
          cadenceConfig={cadenceConfig}
          title='Next Intro'
          uiHints={{
            frequency:
              'How often people will be introduced by Luna Park via group DM',
            nextTriggerTime:
              'Choose the date and time, Luna Park will send intros on the same day of the week each round at approximately time in the chosen time zone',
          }}
          disabled={!perm.managable}
        />
        <ProgramGroupEditor disabled={!perm.managable} />
        <IntroMessageEditor
          programLink={programLink}
          disabled={!perm.managable}
        />
      </div>
    </FormProvider>
  );
}
