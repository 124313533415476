import { useTitle } from 'react-use';

import { makeTitle } from '../../utils/common';
import { ChannelUtils, useChannelPerm } from '../Channel';
import { useOrgConnection, useOrgFeatureContext } from '../Organization';
import {
  ProgramActivateButton,
  ProgramDeactivateButton,
} from './ProgramStatusControl';
import { type ProgramDetailProps } from './types';

export function ProgramActivation(
  props: ProgramDetailProps
): JSX.Element | null {
  const { channel, program, programLink } = props;
  const { org, routePrefix } = useOrgFeatureContext();
  useTitle(
    makeTitle(
      `${program.name} Admin | ${ChannelUtils.ChannelName(channel, org)}`
    )
  );
  const perm = useChannelPerm(channel);
  const { connection } = useOrgConnection({
    orgId: channel.orgId,
    immutable: true,
  });

  if (!perm.managable) {
    return <div className='text-white'>Access Denied</div>;
  }

  if (!connection) return <div className='text-white'>Slack Not Connected</div>;

  const managable = channel.exGroupId && channel.status === 'active';

  if (!managable) return <div className='text-white'>N/A</div>;

  return (
    <div className='flex flex-col text-white min-h-100'>
      <div className='max-w-100'>
        {programLink.status === 'active' ? (
          <ProgramDeactivateButton {...props} routePrefix={routePrefix} />
        ) : (
          <ProgramActivateButton {...props} routePrefix={routePrefix} />
        )}
      </div>
    </div>
  );
}
