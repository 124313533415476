import { useNavigate } from '@remix-run/react';
import { addDays } from 'date-fns';
import shuffle from 'lodash/shuffle';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useMemo } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useTitle } from 'react-use';
import { useSWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { proxy, useSnapshot } from 'valtio';

import {
  type DtoChannel,
  type DtoTag,
  EnumsProgramRoundParentType,
  type ModelsWaterCoolerProgramLinkExtensions,
  type ModelsWaterCoolerRoundExtensions,
} from '@lp-lib/api-service-client/public';

import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useBlockLeave } from '../../../hooks/useUnload';
import { apiService } from '../../../services/api-service';
import { NotificationType } from '../../../types';
import {
  castProgram,
  castProgramLink,
  castProgramRounds,
  type WaterCoolerProgram,
  type WaterCoolerProgramLink,
  type WaterCoolerProgramRound,
} from '../../../types/program';
import { makeTitle, uuidv4 } from '../../../utils/common';
import { useChannelPerm } from '../../Channel';
import { GameIcon } from '../../icons/GameIcon';
import { Loading } from '../../Loading';
import { useNotificationDataSource } from '../../Notification/Context';
import { useOrgFeatureContext } from '../../Organization';
import { useUser } from '../../UserContext';
import {
  ProgramCadenceEditor,
  type ProgramCadenceFormData,
} from '../ProgramCadenceEditor';
import { ProgramCategoriesSubscriber } from '../ProgramCategories';
import { useUpdateTabRightNode } from '../ProgramDetailLayout';
import { type ProgramDetailProps } from '../types';
import { ProgramUtils, resetFormAndGoBack } from '../utils';
import { type WaterCoolerTopic } from './types';
import { WaterCoolerProgramUtils } from './utils';
import { type WaterCoolerTopicFormData } from './WaterCoolerTopicEditor';
import { WaterCoolerTopicQueue } from './WaterCoolerTopicQueue';

function getSystemTopics(
  program: WaterCoolerProgram,
  roundsMap: Map<string, WaterCoolerProgramRound>
): WaterCoolerTopic[] {
  const topics: WaterCoolerTopic[] = [];
  for (const roundId of program.extensions?.roundIds || []) {
    const round = roundsMap.get(roundId);
    if (!round) continue;
    topics.push(WaterCoolerProgramUtils.BuildTopic(round));
  }
  return topics;
}

function isRoundSubscribed(
  round: WaterCoolerProgramRound,
  programLink: WaterCoolerProgramLink
) {
  if (
    round.parentType ===
    EnumsProgramRoundParentType.ProgramRoundParentTypeProgramLink
  )
    return true;
  return !!round.tags?.some((t) =>
    programLink.tagSettings?.selectedTagIds?.includes(t.id)
  );
}

function getSubscribedTopics(
  program: WaterCoolerProgram,
  programLink: WaterCoolerProgramLink,
  roundsMap: Map<string, WaterCoolerProgramRound>
) {
  const subscribedTopicMap = new Map<string, WaterCoolerTopic>();
  for (const link of programLink.extensions?.links || []) {
    const round = roundsMap.get(link.roundId);
    if (!round) continue;
    if (subscribedTopicMap.has(round.id)) continue;
    if (!isRoundSubscribed(round, programLink)) continue;
    subscribedTopicMap.set(
      round.id,
      WaterCoolerProgramUtils.BuildTopic(round, link)
    );
  }
  for (const roundId of program.extensions?.roundIds || []) {
    const round = roundsMap.get(roundId);
    if (!round) continue;
    if (subscribedTopicMap.has(round.id)) continue;
    if (!isRoundSubscribed(round, programLink)) continue;
    subscribedTopicMap.set(round.id, WaterCoolerProgramUtils.BuildTopic(round));
  }
  return Array.from(subscribedTopicMap.values());
}

type FormData = ProgramCadenceFormData & {
  subscribedTopics?: WaterCoolerTopic[];
};

function makeDefaultValues(
  programLink: WaterCoolerProgramLink,
  subscribedTopics: WaterCoolerTopic[],
  roundTimeToMinutes: number
): FormData {
  const cadenceSettings =
    programLink.cadenceSettings || WaterCoolerProgramUtils.DefaultCadence();

  return {
    cadenceSettings: {
      frequency: cadenceSettings.frequency,
      weekdays: cadenceSettings.weekdays,
      nextTriggerTime: {
        date: cadenceSettings?.nextTriggerTime
          ? new Date(cadenceSettings.nextTriggerTime)
          : ProgramUtils.NextCadenceAfter(
              cadenceSettings?.frequency,
              cadenceSettings?.weekdays,
              undefined,
              roundTimeToMinutes
            ),
        timezone:
          cadenceSettings?.timezone ??
          Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
    subscribedTopics,
  };
}

function TopicsSection(props: {
  channel: DtoChannel;
  programLink: WaterCoolerProgramLink;
  systemTopics: WaterCoolerTopic[];
  disabled?: boolean;
}) {
  const { channel, programLink, systemTopics, disabled } = props;

  const { watch, getValues, setValue } = useFormContext<FormData>();
  const topicsFieldArray = useFieldArray<FormData, 'subscribedTopics', 'key'>({
    name: 'subscribedTopics',
    keyName: 'key',
  });

  const cadenceSettings = watch('cadenceSettings');
  const categoryState = useInstance(() =>
    proxy({
      selectedTagIds: programLink.tagSettings?.selectedTagIds || [],
    })
  );

  const { send: sendNotification } = useNotificationDataSource();
  const user = useUser();
  const toast = (message: string) => {
    sendNotification({
      id: uuidv4(),
      toUserClientId: user.id,
      type: NotificationType.WaterCoolerAction,
      createdAt: Date.now(),
      metadata: {
        content: message,
      },
    });
  };

  const tags = useMemo(
    () =>
      uniqBy(
        systemTopics?.flatMap((r) => r.tags ?? []),
        'id'
      ).sort((a, b) => a.name.localeCompare(b.name)),
    [systemTopics]
  );

  const refreshTopicsLinks = async () => {
    const topics = getValues('subscribedTopics') || [];
    const links = WaterCoolerProgramUtils.BuildTopicLinks(topics);
    const extensions: ModelsWaterCoolerProgramLinkExtensions = {
      ...programLink.extensions,
      links,
    };
    await apiService.channel.updateProgramLink(channel.id, programLink.id, {
      tagSettings: {
        selectedTagIds: categoryState.selectedTagIds,
      },
      extensions,
    });
  };

  const handleAddTopic = async (
    req: WaterCoolerTopicFormData,
    index: number
  ) => {
    const extensions: ModelsWaterCoolerRoundExtensions = {
      text: req.text,
      media: req.media,
    };
    const round = (
      await apiService.channel.createProgramRound(channel.id, programLink.id, {
        tags: req.tags.map((t) => t.name),
        extensions,
      })
    ).data.programRound;
    const topic = WaterCoolerProgramUtils.BuildTopic(
      round as WaterCoolerProgramRound,
      {
        roundId: round.id,
        isActive: true,
      }
    );
    topicsFieldArray.insert(index, topic);
    refreshTopicsLinks();
  };

  const handleDeleteTopic = async (topic: WaterCoolerTopic, index: number) => {
    await apiService.channel.deleteProgramRound(
      channel.id,
      programLink.id,
      topic.id
    );
    topicsFieldArray.remove(index);
    refreshTopicsLinks();
  };

  const handleUpdateTopic = async (
    topic: WaterCoolerTopic,
    req: WaterCoolerTopicFormData,
    index: number
  ) => {
    const extensions: ModelsWaterCoolerRoundExtensions = {
      text: req.text,
      media: req.media,
    };
    const round = (
      await apiService.channel.updateProgramRound(
        channel.id,
        programLink.id,
        topic.id,
        {
          tags: req.tags.map((t) => t.name),
          extensions,
        }
      )
    ).data.programRound;
    const updated = WaterCoolerProgramUtils.BuildTopic(
      round as WaterCoolerProgramRound,
      {
        roundId: round.id,
        isActive: topic.isActive ?? true,
      }
    );
    topicsFieldArray.update(index, updated);
  };

  const handleMoveTopic = async (from: number, to: number) => {
    topicsFieldArray.move(from, to);
    refreshTopicsLinks();
  };

  const handleToggleTopicStatus = async (
    topic: WaterCoolerTopic,
    active: boolean,
    index: number
  ) => {
    topicsFieldArray.update(index, {
      ...topic,
      isActive: active,
    });
    refreshTopicsLinks();
  };

  const handleSubscribeTag = async (tag: DtoTag) => {
    categoryState.selectedTagIds.push(tag.id);
    const newTopics = systemTopics.filter(
      (t) =>
        t.tags?.some((t) => t.id === tag.id) &&
        !topicsFieldArray.fields.some((topic) => topic.id === t.id)
    );
    topicsFieldArray.append(newTopics);
    await refreshTopicsLinks();
    toast('✅ You have subscribed to a new category!');
  };

  const handleUnsubscribeTag = async (tag: DtoTag) => {
    const idx = categoryState.selectedTagIds.findIndex((t) => t === tag.id);
    if (idx > -1) {
      categoryState.selectedTagIds.splice(idx, 1);
    }

    const removeIndexes = [];
    for (const [index, topic] of topicsFieldArray.fields.entries()) {
      if (
        topic.parentType ===
        EnumsProgramRoundParentType.ProgramRoundParentTypeProgramLink
      )
        continue;
      if (topic.tags.some((t) => categoryState.selectedTagIds.includes(t.id)))
        continue;
      removeIndexes.push(index);
    }
    topicsFieldArray.remove(removeIndexes);
    await refreshTopicsLinks();
    toast('✅ You have unsubscribed from a category!');
  };

  const handleShuffleTopics = async () => {
    const updated = shuffle(topicsFieldArray.fields);
    setValue('subscribedTopics', updated);
    await refreshTopicsLinks();
  };

  const showTopics = useMemo(() => {
    const results: WaterCoolerTopic[] = [];
    let nextTriggerTime = cadenceSettings.nextTriggerTime.date;
    for (const topic of topicsFieldArray.fields) {
      if (topic.isActive) {
        results.push({
          ...topic,
          sendAt: nextTriggerTime,
        });
        if (cadenceSettings.frequency === 0) {
          for (let i = 0; i < 7; i++) {
            nextTriggerTime = addDays(nextTriggerTime, 1);
            if (
              cadenceSettings.weekdays.length === 0 ||
              cadenceSettings.weekdays.includes(nextTriggerTime.getDay())
            ) {
              break;
            }
          }
        } else {
          nextTriggerTime = addDays(
            nextTriggerTime,
            cadenceSettings.frequency * 7
          );
        }
      } else {
        results.push({
          ...topic,
          sendAt: null,
        });
      }
    }
    return results;
  }, [
    cadenceSettings.frequency,
    cadenceSettings.nextTriggerTime.date,
    cadenceSettings.weekdays,
    topicsFieldArray.fields,
  ]);

  const selectedTagIds = useSnapshot(
    categoryState.selectedTagIds
  ) as typeof categoryState.selectedTagIds;

  return (
    <section className='w-full flex flex-col gap-5'>
      <header className='flex items-center gap-2 text-2xl font-medium'>
        <GameIcon className='w-7.5 h-7.5 fill-current' />
        <div>Select Categories</div>
      </header>
      <main className='mt-6 flex flex-col gap-6'>
        <ProgramCategoriesSubscriber
          tags={tags}
          selectedTagIds={selectedTagIds}
          onAdd={handleSubscribeTag}
          onRemove={handleUnsubscribeTag}
          disabled={disabled}
        />
        <WaterCoolerTopicQueue
          context='programLink'
          programId={programLink.programId || ''}
          topics={showTopics}
          onAdd={handleAddTopic}
          onUpdate={handleUpdateTopic}
          onDelete={handleDeleteTopic}
          onMove={handleMoveTopic}
          onToggleStatus={handleToggleTopicStatus}
          onShuffle={handleShuffleTopics}
          editable={!disabled}
        />
      </main>
    </section>
  );
}

function SubmitActions(props: {
  isSubmitting: boolean;
  onSave: () => void;
  onCancel: () => void;
}) {
  const { isSubmitting, onSave, onCancel } = props;
  return (
    <div className='flex items-center justify-center gap-2 mb-4'>
      <button
        className='btn-secondary w-26 h-10 flex items-center justify-center'
        type='button'
        onClick={onCancel}
      >
        Cancel
      </button>
      <button
        className='btn-primary w-26 h-10 flex items-center justify-center gap-1'
        type='button'
        disabled={isSubmitting}
        onClick={onSave}
      >
        {isSubmitting && <Loading text='' />}
        Save
      </button>
    </div>
  );
}

function Container(props: {
  channel: DtoChannel;
  program: WaterCoolerProgram;
  programLink: WaterCoolerProgramLink;
  rounds: WaterCoolerProgramRound[];
  onBack: () => void;
}) {
  const { channel, program, programLink, rounds, onBack } = props;
  const perm = useChannelPerm(channel);
  const cadenceConfig = useInstance(() =>
    WaterCoolerProgramUtils.GetCadenceConfig()
  );

  const roundsMap = useMemo(
    () => new Map(rounds.map((r) => [r.id, r])),
    [rounds]
  );
  const systemTopics = useMemo(
    () => getSystemTopics(program, roundsMap),
    [program, roundsMap]
  );
  const subscribedTopics = useMemo(
    () => getSubscribedTopics(program, programLink, roundsMap),
    [program, programLink, roundsMap]
  );

  const form = useForm<FormData>({
    defaultValues: makeDefaultValues(
      programLink,
      subscribedTopics,
      cadenceConfig.roundTimeToMinutes
    ),
    mode: 'onChange',
  });

  const handleSave = useLiveCallback(async () => {
    await form.handleSubmit(async (data) => {
      const extensions: ModelsWaterCoolerProgramLinkExtensions = {
        ...programLink.extensions,
        links: WaterCoolerProgramUtils.BuildTopicLinks(
          data.subscribedTopics || []
        ),
      };
      await apiService.channel.updateProgramLink(
        programLink.channelId,
        programLink.id,
        {
          cadenceSettings: {
            frequency: data.cadenceSettings.frequency,
            nextTriggerTime:
              data.cadenceSettings.nextTriggerTime.date.toISOString(),
            timezone: data.cadenceSettings.nextTriggerTime.timezone,
            weekdays:
              data.cadenceSettings.frequency === 0
                ? data.cadenceSettings.weekdays
                : [],
          },
          extensions,
        }
      );
    })();
    resetFormAndGoBack(form, onBack);
  });

  const handleCancel = useLiveCallback(() => resetFormAndGoBack(form, onBack));

  const { isDirty, isSubmitting } = form.formState;
  const updateTabRightNode = useUpdateTabRightNode();
  useEffect(() => {
    if (!perm.managable) return;
    updateTabRightNode(
      <SubmitActions
        isSubmitting={isSubmitting}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    );
    return () => updateTabRightNode(null);
  }, [
    handleCancel,
    handleSave,
    updateTabRightNode,
    isSubmitting,
    perm.managable,
  ]);
  useBlockLeave(() => !isDirty);

  return (
    <FormProvider {...form}>
      <div
        className='w-full flex flex-col items-center justify-center gap-15 
    text-white mt-8'
      >
        <ProgramCadenceEditor
          title='Next Send Time'
          cadenceConfig={cadenceConfig}
          uiHints={{
            frequency: 'How often Luna Park will send a topic',
            nextTriggerTime:
              'Choose the date and time that you want the next topic to be sent, it will be sent at approximately that time in the chosen time zone',
          }}
          disabled={!perm.managable}
        />
        <TopicsSection
          channel={channel}
          programLink={programLink}
          systemTopics={systemTopics}
          disabled={!perm.managable}
        />
      </div>
    </FormProvider>
  );
}

export function WaterCoolerProgramDetails(props: ProgramDetailProps) {
  useTitle(makeTitle(`${props.program.name || 'Details'}`));
  const {
    data,
    isValidating,
    mutate: mutateTopics,
  } = useSWRImmutable(
    `/water-cooler-rounds/${props.programLink.id}`,
    async () => {
      const [programResp, programRoundsResp, linkRoundsResp] =
        await Promise.all([
          apiService.program.getProgram(props.program.id),
          apiService.program.queryRounds(props.program.id),
          apiService.program.queryRounds(props.programLink.id),
        ]);
      const program = castProgram<WaterCoolerProgram>(programResp.data.program);
      const rounds = castProgramRounds<WaterCoolerProgramRound>([
        ...linkRoundsResp.data.programRounds,
        ...programRoundsResp.data.programRounds,
      ]);
      return { program, rounds };
    }
  );
  const { routePrefix } = useOrgFeatureContext();
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const mutateAndGoBack = () => {
    mutateTopics();
    mutate(`/channels/program-links/${props.programLink.id}`);
    navigate(`${routePrefix}/channels`);
  };

  if (isValidating) return <Loading text='' />;
  if (!data) return null;
  return (
    <Container
      channel={props.channel}
      program={data.program}
      programLink={castProgramLink<WaterCoolerProgramLink>(props.programLink)}
      rounds={data.rounds}
      onBack={mutateAndGoBack}
    />
  );
}
