import { useMemo } from 'react';
import useSWR, { type KeyedMutator } from 'swr';

import { apiService } from '../../../services/api-service';
import { type CelebrationParticipant } from '../../../types/celebration';

interface useCelebrationParticipantsReturned {
  participants: CelebrationParticipant[];
  isLoading: boolean;
  error?: Error | null;
  mutate: KeyedMutator<CelebrationParticipant[]>;
}

export function useCelebrationParticipants(props: {
  id: string;
}): useCelebrationParticipantsReturned {
  const { data, error, isValidating, mutate } = useSWR(
    props.id ? `/celebrations/${props.id}/participants` : null,
    async () =>
      (await apiService.celebration.getParticipants(props.id)).data.participants
  );

  return useMemo(
    () => ({
      participants: data || [],
      isLoading: !data && isValidating,
      error,
      mutate,
    }),
    [data, isValidating, error, mutate]
  );
}
