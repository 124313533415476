import { type ReactNode } from 'react';

export function ProgramMgmtLayout(props: {
  children?: ReactNode;
}): JSX.Element {
  return (
    <div className='w-full flex-1 flex items-center justify-center overflow-y-auto scrollbar bg-game-library bg-w-full bg-no-repeat'>
      <div className='w-5/6 h-full flex-col items-center justify-center self-center min-w-192'>
        {props.children}
        <div className='h-10'></div>
      </div>
    </div>
  );
}
