import { useNavigate } from '@remix-run/react';
import { useEffectOnce } from 'react-use';

import { type EnumsProgramType } from '@lp-lib/api-service-client/public';

import { getQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { ChannelUtils } from '../Channel';
import { GlobalLoading } from '../GlobalLoading';
import { useMyOrgId } from '../Organization';

export function ProgramLinkNew() {
  const orgId = useMyOrgId();
  const navigate = useNavigate();

  useEffectOnce(() => {
    const run = async () => {
      if (!orgId) return;

      const programType = getQueryParam('program-type') as EnumsProgramType;
      const channelId = getQueryParam('channel-id');
      if (!programType || !channelId) return;

      const programs = (await apiService.program.getActivePrograms()).data
        .activePrograms;
      const program = programs.find((p) => p.type === programType);
      if (!program) return;

      const {
        data: { channels },
      } = await apiService.slack.queryChannels({
        type: 'byIds',
        orgId,
        channelIds: channelId,
      });
      if (channels.length === 0) return;
      const slackChannel = channels[0];

      const { programLink } =
        await ChannelUtils.InstallProgramInExistingChannel({
          orgId,
          program,
          slackChannel,
        });

      navigate(`/programs/${programLink.id}`);
    };

    run();
  });

  return <GlobalLoading />;
}
