import { useCallback, useState } from 'react';

import {
  type DtoChannel,
  type DtoNewMember,
} from '@lp-lib/api-service-client/public';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { type Organization, type Organizer } from '../../types';
import { err2s } from '../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { Loading } from '../Loading';
import { OrganizerPicker } from '../Organization/OrganizerPicker';

type Props = {
  org: Organization;
  channel: DtoChannel;
  excludedUids?: string[];
  onComplete: () => void;
  onCancel: () => void;
};

function AddMembersFromOrgModal(props: Props): JSX.Element {
  const { org, channel, excludedUids = [] } = props;
  const [organizer, setOrganizer] = useState<Organizer | null>(null);

  const handlePick = (organizer: Organizer) => {
    setOrganizer(organizer);
  };

  const {
    state: { transformed: state },
    error,
    call: addMembers,
  } = useAsyncCall(
    useCallback(async (channelId: string, organizers: Organizer[]) => {
      const newMembers = organizers.map<DtoNewMember>((o) => ({
        uid: o.uid,
        exUserId: o.exUserId,
        isNewInvited: false,
      }));
      const resp = await apiService.channel.addMembers(channelId, {
        members: newMembers,
      });
      return resp.data.members;
    }, [])
  );

  const handleAdd = async () => {
    if (!organizer) return;
    const members = await addMembers(channel.id, [organizer]);
    if (members === undefined) return;
    props.onComplete();
  };

  const handleFilter = useCallback(
    (organizers: Organizer[]): Organizer[] => {
      return organizers.filter((o) => !excludedUids.includes(o.uid));
    },
    [excludedUids]
  );

  return (
    <ModalWrapper containerClassName='w-160' borderStyle='gray'>
      <div className='w-full h-full min-h-52 flex flex-col items-center justify-center px-12 py-10'>
        <header className='font-medium text-2xl flex flex-col items-center'>
          <div className='text-center'>
            Add Participants from <div>{org.name}</div>
          </div>
        </header>
        <section className='w-full my-5'>
          <p className='font-bold mb-1'>Member</p>
          <OrganizerPicker
            orgId={org.id}
            onChange={handlePick}
            filterOrganizers={handleFilter}
            autoFocus
          />
          {error && <div className='text-red-002 text-sms'>{err2s(error)}</div>}
        </section>
        <footer className='w-full flex items-center justify-center gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 h-10 flex items-center justify-center'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 h-10 flex items-center justify-center'
            disabled={!organizer || state.isRunning}
            onClick={handleAdd}
          >
            {state.isRunning && <Loading text='' />}
            <div className='ml-2'>Confirm</div>
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

export function useTriggerAddMembersFromOrgModal(): (
  props: Omit<Props, 'onCancel'>
) => void {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  return useCallback(
    (props: Omit<Props, 'onCancel'>) => {
      triggerFullScreenModal({
        kind: 'custom',
        containerClassName: 'bg-black bg-opacity-60',
        element: (p) => (
          <AddMembersFromOrgModal
            org={props.org}
            channel={props.channel}
            excludedUids={props.excludedUids}
            onComplete={() => {
              p.internalOnConfirm();
              props.onComplete();
            }}
            onCancel={p.internalOnConfirm}
          />
        ),
      });
    },
    [triggerFullScreenModal]
  );
}
