/* eslint-disable react/jsx-no-undef */
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { type ReactNode } from 'react';

import { type CalendarProgramRound } from '../../../types/program';
import { fromDTOGamePack } from '../../../utils/api-dto';
import { useOpenGamePackDetailPage } from '../../Game/GamePack';
import { GamePackCover } from '../../Game/Utilities';
import { ArrowRightIcon } from '../../icons/Arrows';
import { GlobeIcon } from '../../icons/GlobeIcon';
import { MessageLogicList } from '../../Message/MessageLogicList';
import { type ProgramDetailProps } from '../types';
import { useMessageCampaignProgramRoundCalendar } from './CalendarProgramMessages';
import { CalendarProgramRoundEntry } from './CalendarProgramRoundEntry';

function Item(props: { label: string; children?: ReactNode }) {
  return (
    <div className='flex flex-col gap-4'>
      <div className='text-sms text-icon-gray'>{props.label}</div>
      {props.children}
    </div>
  );
}
export function CalendarProgramRoundDetail(
  props: ProgramDetailProps & {
    round: CalendarProgramRound;
    timezone: string;
    onBack: () => void;
  }
) {
  const { program, programLink, channel, timezone, round } = props;
  const openGamePack = useOpenGamePackDetailPage();
  const pack = round.extensions?.gamePack;

  const campaign = useMessageCampaignProgramRoundCalendar({
    programBasicSettings: program.basicSettings,
    round,
    programLink,
    channel,
  });

  return (
    <div className='w-full'>
      <div className='font-bold cursor-pointer' onClick={props.onBack}>
        &lt;- Back
      </div>

      <div className='flex flex-col gap-12.5'>
        <div className='mt-8 flex flex-col gap-6'>
          <div className='text-2xl font-medium flex items-center gap-2.5'>
            <GlobeIcon className='w-7.5 h-7.5 fill-current' />
            <div>General</div>
          </div>
          <Item label={`${program.name} Name`}>
            <div>{round.label ?? 'N/A'}</div>
          </Item>
          <Item label='Game Pack'>
            <div className='flex items-center gap-3'>
              <div className='w-22.5 h-12.5 flex-none'>
                <GamePackCover pack={fromDTOGamePack(pack)} />
              </div>
              {round.extensions?.gamePack?.name ?? 'N/A'}
              {pack && (
                <button
                  type='button'
                  className='btn text-sms font-medium ml-10'
                  onClick={() => openGamePack(fromDTOGamePack(pack))}
                >
                  View
                </button>
              )}
            </div>
          </Item>
          <Item label='Start Date'>
            {round.startedAt
              ? format(
                  utcToZonedTime(round.startedAt, timezone),
                  'MMMM d, yyyy h:mm aa'
                )
              : 'N/A'}
          </Item>
          <Item label='Categories'>
            {round.tags?.map((t) => t.name).join(', ') ?? 'N/A'}
          </Item>
        </div>
        <MessageLogicList campaign={campaign} />
      </div>
    </div>
  );
}

export function CalendarProgramRoundList(
  props: ProgramDetailProps & {
    rounds: CalendarProgramRound[];
    timezone: string;
    onClick: (round: CalendarProgramRound) => void;
  }
) {
  const { rounds, timezone } = props;
  return (
    <div className='w-full flex flex-col gap-4'>
      <div className='flex flex-col w-full gap-2'>
        {rounds.map((r) => (
          <CalendarProgramRoundEntry
            key={r.id}
            round={r}
            timezone={timezone}
            action={
              <button
                type='button'
                className='btn flex items-center justify-center w-7.5 h-7.5 
                rounded-lg bg-dark-gray border border-secondary'
                onClick={() => props.onClick(r)}
              >
                <ArrowRightIcon />
              </button>
            }
          />
        ))}
      </div>
      {rounds.length === 0 && (
        <div className='w-full flex items-center justify-center text-secondary'>
          No Rounds
        </div>
      )}
    </div>
  );
}
