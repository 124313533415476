import { useNavigate } from '@remix-run/react';
import { useMemo } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Select from 'react-select';
import useSWR, { useSWRConfig } from 'swr';
import { match } from 'ts-pattern';

import { type DtoRecognitionProgramLinkExtensions } from '@lp-lib/api-service-client/public';

import { useInstance } from '../../../hooks/useInstance';
import { useTitle } from '../../../hooks/useTitle';
import { apiService } from '../../../services/api-service';
import {
  castProgramLink,
  type RecognitionProgramLink,
} from '../../../types/program';
import { err2s, makeTitle } from '../../../utils/common';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { useChannelPerm } from '../../Channel';
import { type Option } from '../../common/Utilities';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../../ConfirmCancelModalContext';
import { Loading } from '../../Loading';
import { useOrgFeatureContext } from '../../Organization';
import { type ProgramDetailProps } from '../types';
import { type EmojiOption, RecognitionProgramUtils } from './utils';

const GIVING_AMOUNT_OPTIONS = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 100, 200, 500,
].map<Option<number>>((v) => ({ label: `${v}`, value: v }));

function valueToAmountOption(val: number) {
  return GIVING_AMOUNT_OPTIONS.find((o) => o.value === val);
}

type FormData = DtoRecognitionProgramLinkExtensions;

function InstalledChannels() {
  const { org } = useOrgFeatureContext();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const swr = useSWR(
    `/slack/channels?orgId=${org.id}&type=byUserId`,
    async () => {
      const resp = await apiService.slack.queryChannels({
        type: 'byUserId',
        orgId: org.id,
      });
      return resp.data.channels;
    }
  );

  const onClick = () => {
    triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>
            Add More Channels
          </ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            To enable Ice Cream Shout-outs in other slack channels, invite @Luna
            Park to that channel.
          </ConfirmCancelModalText>
        </div>
      ),
      confirmOnly: true,
      confirmBtnLabel: 'OK',
    });
  };
  return (
    <div className='w-full flex items-start justify-start gap-10'>
      <div className='flex justify-start text-sms w-100'>
        <div className='flex flex-col'>
          <div className='text-sms font-bold'>Installed in:</div>
          {match(swr)
            .when(
              () => swr.isLoading,
              () => <Loading />
            )
            .when(
              () => !!swr.error,
              () => (
                <div className='text-sms text-red-002'>{err2s(swr.error)}</div>
              )
            )
            .otherwise(() => (
              <div className='text-sms text-icon-gray'>
                {swr.data?.map((c) => `#${c.name}`).join(', ') ?? 'N/A'}
              </div>
            ))}
        </div>
      </div>
      <button
        type='button'
        className='btn text-primary text-sms flex-nowrap whitespace-nowrap'
        onClick={onClick}
      >
        + Add more channels
      </button>
    </div>
  );
}

function MembersGivingAmoutEditor(props: { disabled?: boolean }) {
  const { control } = useFormContext<FormData>();
  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<number>>({
        override: {
          control: { height: '100%' },
        },
      }),
    []
  );
  return (
    <Controller
      name='membersDailyGivingAmount'
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className='flex flex-col'>
          <div className='font-bold mb-2'>
            Employees can give this many ice creams daily
          </div>
          <Select<Option<number>>
            options={GIVING_AMOUNT_OPTIONS}
            value={valueToAmountOption(value)}
            classNamePrefix='select-box-v2'
            className='w-85 h-13.5'
            styles={styles}
            onChange={(option) => onChange(option?.value)}
            isDisabled={props.disabled}
          />
        </div>
      )}
    />
  );
}

function AdminGivingAmoutEditor(props: { disabled?: boolean }) {
  const { control } = useFormContext<FormData>();
  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<number>>({
        override: {
          control: { height: '100%' },
        },
      }),
    []
  );
  return (
    <Controller
      name='adminDailyGivingAmount'
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className='flex flex-col'>
          <div className='font-bold mb-2'>
            Admins can give this many ice creams daily
          </div>
          <Select<Option<number>>
            options={GIVING_AMOUNT_OPTIONS}
            value={valueToAmountOption(value)}
            classNamePrefix='select-box-v2'
            className='w-85 h-13.5'
            styles={styles}
            onChange={(option) => onChange(option?.value)}
            isDisabled={props.disabled}
          />
        </div>
      )}
    />
  );
}

function EmojiTriggerEditor(props: { disabled?: boolean }) {
  const { control } = useFormContext<FormData>();
  const styles = useMemo(
    () =>
      buildReactSelectStyles<EmojiOption>({
        override: {
          control: { height: '100%' },
        },
      }),
    []
  );

  const options = useInstance(() => RecognitionProgramUtils.GetEmojiOptions());

  return (
    <Controller
      name='emoji'
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className='flex flex-col'>
          <div className='font-bold mb-2'>Emoji Trigger</div>
          <Select<EmojiOption>
            options={options}
            onChange={(o) => onChange(o)}
            value={options.find((o) => o.value === value?.value)}
            classNamePrefix='select-box-v2'
            className='w-85 h-13.5'
            styles={styles}
            getOptionLabel={(option) => `${option.token} ${option.name}`}
            isDisabled={props.disabled}
          />
        </div>
      )}
    />
  );
}

function RecognitionConfigForm(props: ProgramDetailProps) {
  const perm = useChannelPerm(props.channel);
  const programLink = castProgramLink<RecognitionProgramLink>(
    props.programLink
  );
  const form = useForm<FormData>({
    defaultValues: {
      ...programLink.extensions,
    },
  });

  const { routePrefix } = useOrgFeatureContext();
  const navigate = useNavigate();

  const { isSubmitting, isValid, isDirty } = form.formState;

  const { mutate } = useSWRConfig();
  const mutateAndGoBack = () => {
    mutate(`/channels/program-links/${programLink.id}`);
    navigate(`${routePrefix}/channels`);
  };

  const onSave = form.handleSubmit(async (data) => {
    if (data.emoji) {
      data.emoji.default = true;
    }
    await apiService.channel.updateProgramLink(
      programLink.channelId,
      programLink.id,
      {
        extensions: data,
      }
    );
    mutateAndGoBack();
  });

  return (
    <FormProvider {...form}>
      <div className='w-full flex flex-col justify-center gap-8 text-white'>
        <InstalledChannels />
        <EmojiTriggerEditor disabled={!perm.managable} />
        <MembersGivingAmoutEditor disabled={!perm.managable} />
        <AdminGivingAmoutEditor disabled={!perm.managable} />
        <button
          className='btn-primary w-26 h-10 flex items-center justify-center gap-1'
          type='button'
          disabled={!isValid || isSubmitting || !isDirty}
          onClick={onSave}
        >
          {isSubmitting && <Loading text='' />}
          Save
        </button>
      </div>
    </FormProvider>
  );
}

export function RecognitionProgramUserSettings(props: ProgramDetailProps) {
  useTitle(makeTitle('Ice Cream Shout-outs Setup'));
  return <RecognitionConfigForm {...props} />;
}
