import { format } from 'date-fns';
import { useTitle } from 'react-use';

import { type DtoBasicProgram } from '@lp-lib/api-service-client/public';

import placeholder from '../../../assets/img/placeholder/game-cover.png';
import { type WaterCoolerProgramRound } from '../../../types/program';
import { err2s, makeTitle } from '../../../utils/common';
import { Loading } from '../../Loading';
import { type ProgramDetailProps } from '../types';
import { useWaterCoolerPastRounds } from './hooks';
import { useTriggerWaterCoolerTopicPreviewModal } from './WaterCoolerTopicEditor';

function WaterCoolerProgramPastRoundCard(props: {
  round: WaterCoolerProgramRound;
  program: DtoBasicProgram;
}) {
  const snapshot = props.round.extensions?.snapshot;

  const triggerPreviewModal = useTriggerWaterCoolerTopicPreviewModal();

  const preview = () => {
    triggerPreviewModal({
      programId: props.program.id,
      text: snapshot?.text || '',
      media: snapshot?.media,
    });
  };

  return (
    <div
      className='
              w-full bg-modal border border-secondary rounded-xl 
              p-2 pr-5 flex justify-between items-center gap-2
              text-sms text-white hover:bg-lp-gray-003 cursor-pointer
            '
      onClick={preview}
    >
      <img
        src={snapshot?.media?.media?.url || placeholder}
        alt='cover'
        className={`object-cover rounded w-40 h-22.5`}
      />
      <div className='w-80 font-bold line-clamp-4'>{snapshot?.text}</div>
      <div className='flex flex-col gap-1.5 text-[#8C6FFF] w-20'>
        {!!snapshot?.tags.length ? (
          snapshot.tags?.map((t) => (
            <div key={t} className='text-3xs'>
              {t}
            </div>
          ))
        ) : (
          <div className='text-3xs text-[#ff8f00]'>Custom</div>
        )}
      </div>

      <div className='w-30 text-right whitespace-pre-wrap'>
        {props.round.endedAt
          ? `${format(new Date(props.round.endedAt), 'EEE, MMM do')}\n${format(
              new Date(props.round.endedAt),
              'h:mm a'
            )}`
          : ''}
      </div>

      <button
        type='button'
        className='btn text-sms hover:text-primary'
        onClick={preview}
      >
        Preview
      </button>
    </div>
  );
}

export function WaterCoolerProgramPastRounds(props: ProgramDetailProps) {
  useTitle(makeTitle(`Past ${props.program.name || 'Rounds'}`));

  const {
    data: rounds,
    isLoading,
    error,
  } = useWaterCoolerPastRounds(props.programLink.id);

  if (isLoading) return <Loading text='' />;
  if (error) return <div className='text-sms text-red-002'>{err2s(error)}</div>;
  return (
    <div className='w-full flex items-center justify-center text-white mt-8'>
      <div className='w-full flex flex-col gap-2.5'>
        {rounds.map((round) => (
          <WaterCoolerProgramPastRoundCard
            key={round.id}
            program={props.program}
            round={round}
          />
        ))}
      </div>
    </div>
  );
}
